import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@board/store/types'
import * as accountTypes from '@account/store/types'
import localStorageHelper from '@appbase/util/local-storage-helper'
import { readablizeBytes } from '@appbase/util/size-helper'
import { getGUID } from '@appbase/util/guid'
import { dispatchEventBus } from '@appbase/util/eventBus'
import { FlowFileMeta } from '@appbase/components/common/FlowFileUploader/flow-file-meta'
import { invalidateUrl } from '@appbase/util/invalidate-url'
import defaultOptions from '@board/components/yooncoms/default.option'
import * as aes from '@appbase/util/aes-encrypt'
import api from '@board/api'
import flowHelper from '@appbase/components/common/FlowFileUploader/flow-helper'
import axios from 'axios'

import { EumEditor } from '@appbase/components/editor'
import { TinyEditor } from '@appbase/components/editor/TinyEditor'
import { FlowFileUploader } from '@appbase/components/common'
import TextField from '@board/components/board/writeComponents/TextField.vue'
import TextAreaField from '@board/components/board/writeComponents/TextAreaField.vue'
import SelectField from '@board/components/board/writeComponents/SelectField.vue'
import DatetimeField from '@board/components/board/writeComponents/DatetimeField.vue'
import TimeSpanField from '@board/components/board/writeComponents/TimeSpanField.vue'
import MailSendField from '@board/components/board/writeComponents/MailSendField.vue'
import TagField from '@board/components/board/writeComponents/TagField.vue'
import { LoadingBar } from '@appbase/components/loading'
import SelectUserField from '@board/components/board/writeComponents/SelectUserField.vue'
import CheckField from '@board/components/board/writeComponents/CheckField.vue'
import menuHelper from '@appbase/util/menu-helper'
import { ModalLayout } from '@appbase/templates'

export enum WriteMode {
    Write = 0,
    Edit = 1,
    Reply = 2,
}

var ids: common.BoardFieldSetting = (window as any).eumBoardIds as common.BoardFieldSetting

@Component({
    components: {
        TinyEditor,
        FlowFileUploader,
        TagField,
        LoadingBar,
        EumEditor,
        ModalLayout
    },

    computed: mapGetters({
        user: accountTypes.USER,
        current: accountTypes.CURRENT,
        popupBaseUrl: types.POPUP_BASEURL,
    }),

    methods: mapActions({
        readArticle: types.READ_ARTICLE,
        fetchFile: types.FETCH_FILE,
        createFile: types.CREATE_FILE,
        createArticle: types.CREATE_ARTICLE,
        updateArticle: types.UPDATE_ARTICLE,
    }),
})
export default class BoardWrite extends Vue {
    @Prop({ type: Boolean, default: false }) isModal!: boolean

    readArticle!: (articleSet: article.ArticleFetchSet) => Promise<article.ArticleDetail>
    fetchFile!: (code: string) => Promise<article.FileStore[]>
    createFile!: (set: article.FileCreateSet) => Promise<number>
    createArticle!: (articleSet: article.ArticleQuerySet) => Promise<common.CreateResultSet>
    updateArticle!: (articleSet: article.ArticleQuerySet) => Promise<common.UpdateResultSet>

    user!: account.User
    current!: account.TCurrent
    popupBaseUrl!: string

    tinyOptions: any = {
        content_style: `
                    p { font-family: dotum; }
                    body {font-family: dotum; font-size: 10pt;}
                    body#tinymce p {
                        font-family: dotum; 
                        margin-top: 0.3em;
                        margin-bottom: 0.3em;
                    }`,
        height: 500,
        inline: false,
        menubar: true,
          
    }

    yoonComsOptions: yooncoms.webeditor.EditorOptions = {
        ...defaultOptions,
        height: 500,
        useMenuBar: false,
        hiddenMenu: [
            'helpGroup',
            'viewGroup',
            'toolGroup',
            //'tableGroup',
            //'insertGroup',
            'bookmark',
            'horizontal',
            'date_format',
            'background_image',
            'emoticon',
            'upper_lower',
            'blockquote',
            'layer',
            'paragraphFormatGroup',
            //'textFormatGroup',
            //'fontSizeGroup',
            //'fontFamilyGroup',
            'headingGroup',
            'editGroup',
            'fileGroup',
        ]
    };

    writeConfig: board.WriteConfig | null = null
    contentFields: board.ContentField[] = []
    returnValues: common.WriteComponentReturnSet[] = []
    uploaderConfig: FlowFileMeta = {
        code: '',
        codeValues: [],
        type: 'file',
    }

    fieldLoaded: boolean = false
    isLoading: boolean = false
    isFileUploading: boolean = false
    currentBoard: number = 0
    currentArticle: number = 0
    currentArticleModel: article.ArticleDetail | null = null
    parentArticle: number = 0
    writeMode: WriteMode = WriteMode.Write

    mUsePreface: boolean = false
    mUseNotice: boolean = false
    mUseAttachment: boolean = true
    mUseTags: boolean = false
    mUseNameless: boolean = false
    mUseContent: boolean = false
    mUseSecret: boolean = false
    mUseAnonComment: boolean = false

    mBoardName: string = ''
    mIsNotice: string = 'none'
    mTitle: string = ''
    mContent: string = ''
    mTags: string[] = []
    mFileCode: string = ''
    mChildPreface: string | null = null
    mParentPreface: string | null = null
    mFileSizeDesc: string = ''
    mWritePassword: string = ''
    mSecret: boolean = false

    childPrefaces: board.BaordPreface[] = []
    parentPrefaces: board.BoardPreface[] = []
    mShowChildPreface: boolean = false

    uploadUrl: string = ''
    deleteUrl: string = ''
    sizeLimit: number | null = null
    bannedExtentions: string[] = []
    files: any[] = []

    //안내문 모달
    showboardNotice : boolean = false
    boardNoticeTemplate : string = ""

    created() {
        ids = (window as any).eumBoardIds;
        var { SYSID, ARTICLEID, PARENTARTICLE } = this.$route.query

        if (SYSID) this.currentBoard = Number(SYSID)

        if (ARTICLEID) {
            this.currentArticle = Number(ARTICLEID)
            this.writeMode = WriteMode.Edit
        } else if (PARENTARTICLE) {
            this.parentArticle = Number(PARENTARTICLE)
            this.writeMode = WriteMode.Reply
        }

        this.mountAction()

        // 20230626 익명 게시판(SPARK) 게시판 ID 및 공지 내용 조회
        // var appConfigBoardId = this.getAppConfig('Z_Board_SPARK_ID')
        // var appConfigBoardNoticeContent = this.getAppConfig('Z_Board_SPARK_NotiContent')

        // if (appConfigBoardId && Number(appConfigBoardId) == this.currentBoard){
        //     if (appConfigBoardNoticeContent && appConfigBoardNoticeContent != '')
        //     {
        //         this.showboardNotice = true;
        //         this.boardNoticeTemplate = appConfigBoardNoticeContent;
        //     }
        // }

        // 20230816 익명 게시판 로직 수정 : Custom 익명 게시판으로 등록되어 있으며, 안내문구가 있는 경우 메시지를 표시한다.
        var customNamelessBoardList = this.getAppConfig('Z_Board_CustomNameless_List');
        if (customNamelessBoardList){
            var jsonData = JSON.parse(customNamelessBoardList);
            var jObj: Array<any> = (jsonData && jsonData["NamelessBoardList"] && jsonData["NamelessBoardList"].length > 0 ? jsonData["NamelessBoardList"] : null);
            if (jObj){
                var namelessBoardInfo = jObj.find(x =>  x.BoardId == this.currentBoard.toString());
                if (namelessBoardInfo){
                    var noticeContent = this.getAppConfig(`Z_Board_CustomNameless_Content_${namelessBoardInfo.ComCode}`);
                    if (noticeContent && noticeContent != ''){
                        this.showboardNotice = true;
                        this.boardNoticeTemplate = noticeContent;
                    }
                }
            }
        }
    }

    updateSizeDesc() {
        if (this.files == null || this.files.length == 0) {
            if (this.sizeLimit != null) {
                this.mFileSizeDesc = `( 0 / ${readablizeBytes(this.sizeLimit)})`
            } else {
                this.mFileSizeDesc = ''
            }
        } else {
            var sizeTotal = this.files.reduce((p, c, i) => p + c.size, 0)

            if (this.sizeLimit == null) {
                this.mFileSizeDesc = `( ${readablizeBytes(sizeTotal)})`
            } else {
                this.mFileSizeDesc = `( ${readablizeBytes(sizeTotal)} / ${readablizeBytes(this.sizeLimit)})`
            }
        }
    }

    fileUploadStatusChaged(data: any) {
        if (data === 'uploading') {
            this.isFileUploading = true
        } else {
            this.isFileUploading = false
        }
    }

    fileItemsChanged(files: any[]) {
        if (files == null || files.length == 0) {
            this.updateSizeDesc()
        } else {
            if (this.writeMode == WriteMode.Edit && this.mFileCode != null) {
                this.createFile({
                    articleId: this.currentArticle,
                    boardId: this.currentBoard,
                    fileCode: this.mFileCode,
                    files: files.map(
                        (f) =>
                            <article.FileItem>{
                                name: f.name,
                                size: f.size,
                            }
                    ),
                })
            }

            this.updateSizeDesc()
        }
    }

    checkEditRights(createdUser: string, systemAuths: article.SystemAuth[]) {
        if (createdUser === this.user.personCode)
            return true;

        if (systemAuths.findIndex(a => a.update || a.isMenuAdmin) >= 0)
            return true;

        if (this.current.permissions.isSuperAdmin)
            return true;

        return false;
    }

    // 지누스 익명게시판 여부 체크
    checkCustomBoard(){
        var isCustomBoard: boolean = false;
        var customBoardList = this.getAppConfig("Z_Board_CustomNameless_List");
        
        if (customBoardList){
            var jsonData = JSON.parse(customBoardList);
            var jObj: Array<any> = (jsonData && jsonData["NamelessBoardList"] && jsonData["NamelessBoardList"].length > 0 ? jsonData["NamelessBoardList"] : null);
            var customBoardInfo = jObj.find(x => x.BoardId == this.currentBoard.toString());
            if (customBoardInfo){
                isCustomBoard = true;
            }
        }

        return isCustomBoard;
    }

    mountAction() {
        api.board.getWriteConfig(this.currentBoard).then(res => {
            this.writeConfig = res as board.WriteConfig
            this.mBoardName = this.writeConfig.boardName
            this.mUsePreface = this.writeConfig.usePreface
            this.mUseNotice = this.writeConfig.usePrimaryNotice
            this.mUseAttachment = this.writeConfig.useAttachment
            this.mUseTags = this.writeConfig.useTag
            this.mUseContent = this.writeConfig.useContent
            this.mUseNameless = this.writeConfig.useNameless;
            this.mUseSecret = this.writeConfig.useSecret
            this.mUseAnonComment = this.writeConfig.useAnonComment
            this.contentFields = this.writeConfig.contentFields.filter((f) => ids.contentFieldFilter(f))
            this.uploaderConfig.codeValues = ['board', this.writeConfig.boardId.toString()]
            this.uploadUrl = invalidateUrl('/cm/file/store/flow')
            this.deleteUrl = invalidateUrl('/cm/file/store/deleteFile')

            if (this.writeMode == WriteMode.Edit) {
                // 지누스 Custom 익명 게시판 여부에 따른 기존 code와 분기 처리
                if (this.checkCustomBoard()){
                    // 관리자인 경우 원본 게시글 데이터 조회
                    api.board.getCustomBoardManagerCheck(this.currentBoard, this.user.personCode).then(async (response) => {
                        if (response){
                            this.readArticle({ boardId: this.currentBoard, articleId: this.currentArticle }).then((resArticle) => {
                                this.currentArticleModel = resArticle;
                                this.mIsNotice = resArticle.isNotice ? "notice" : "none";
                                this.mountCallback(res);
                            });
                        }
                        else {
                            await (window as any).alertAsync(this.$t('M_PermissionError').toString());
                        }
                    }); 
                }
                else {
                    this.checkRightNameless(this.writeConfig).then(checkRes => {
                        if (checkRes) {
                            var editloadPromise: Promise<any>[] = [
                                this.readArticle({ boardId: this.currentBoard, articleId: this.currentArticle }),
                                api.article.getSystemAuth(this.currentBoard)
                            ]
    
                            Promise.all(editloadPromise)
                                .then(async editload => {
                                    this.currentArticleModel = editload[0];
                                    this.mIsNotice = editload[0].isNotice ? "notice" : "none";
                                    if (!res.useNameless && !this.checkEditRights(editload[0].createdUser, editload[1])) {
                                        await (window as any).alertAsync('수정권한이 없습니다.');
                                        this.handleClose(false);
                                    } else {
                                        this.mountCallback(res);
                                    }
                                });
                        }
                    });
                }                
            } else {
                this.mountCallback(res);
            }
        });
    }

    mountCallback(config: board.WriteConfig) {
        if (config.attachableExtension && config.attachableExtension != null) {
            var bannedExtensions = config.attachableExtension.split(';')
            this.bannedExtentions = bannedExtensions
        }

        if (config.attachmentSizeLimit && config.attachmentSizeLimit != null) {
            this.sizeLimit = config.attachmentSizeLimit * 1024 * 1024
            this.mFileSizeDesc = `( 0 / ${readablizeBytes(this.sizeLimit)})`
        } else {
            this.sizeLimit = null
        }

        this.mountPrefaces(config);
        this.fillComponentValues(this.currentArticleModel);
        this.fieldLoaded = true;
    }

    async checkRightNameless(config: board.WriteConfig) {
        if (config.useNameless) {
            var modifyingKey = localStorageHelper.get(`eumboard-nameless-article-${this.currentArticle}`);
            var enterword = ""

            try { enterword = aes.aesDecrypt(modifyingKey); }
            catch {
                return false;
            };

            localStorageHelper.remove(`eumboard-nameless-article-${this.currentArticle}`)
            return await api.article.checkArticlePassword({ articleId: this.currentArticle, password: enterword })
        } else {
            return true;
        }
    }

    fillComponentValues(article: article.ArticleDetail | null) {
        console.log('fillComponentValues', article)
        if (article == null) {
            this.mFileCode = getGUID(false)
            this.uploaderConfig.code = this.mFileCode

            // 메일에서 게시글 등록하는 경우
            const {mailId, subject, fileCode} = this.$route.query
            if (mailId) {
                if (fileCode && this.mUseAttachment) {
                    this.mFileCode = fileCode.toString()
                    this.uploaderConfig.code = fileCode.toString()
                    this.fetchFile(fileCode.toString()).then((res) => {
                        this.files = res.map(
                            (m) => flowHelper.convertToFlowFile({
                                ...m,
                                uniqueIdentifier: m.serverPath,
                                type: 'Database'
                            })
                        )
                        this.updateSizeDesc()
                    })
                }
                this.mTitle = subject.toString()
                var url = invalidateUrl(`/cm/api/ews/body/html?id=${encodeURIComponent(mailId.toString())}`);
                axios.get(url).then(res => {
                    this.mContent = res.data;
                });
            }

            this.contentFields.forEach((c) => {
                this.returnValues.push({
                    fieldId: c.fieldId,
                    returnValue: null,
                })
            })
        } else {
            this.mTitle = article.title
            this.mContent = article.content
            this.mFileCode = article.fileCode || getGUID(false)
            this.mTags = article.tags == null ? [] : article.tags.split(',').filter((t) => t != null && t.trim().length > 0)
            this.mSecret = article.isSecret ?? false
            this.uploaderConfig.code = this.mFileCode
            this.fetchFile(this.mFileCode).then((res) => {
                this.files = res.map(
                    (m) => flowHelper.convertToFlowFile({
                        ...m,
                        uniqueIdentifier: m.serverPath,
                        type: 'Database'
                    })
                )
                this.updateSizeDesc()
            })

            this.contentFields.forEach((c) => {
                var find = article.fieldValues.find((f) => f.fieldId == c.fieldId)
                var passVal = find?.value

                this.returnValues.push({
                    fieldId: c.fieldId,
                    returnValue: passVal ? passVal : null,
                })
            })
        }
    }

    mountPrefaces(config: board.WriteConfig) {
        if (!config.usePreface) return

        var targetPreface: string | null = null

        if (this.writeMode == WriteMode.Reply) {
            var replyPreface = localStorageHelper.get(`$board/${this.parentArticle.toString()}/preface`)
            targetPreface = replyPreface == null ? null : replyPreface.trim()
        } else if (this.writeMode == WriteMode.Edit && this.currentArticleModel != null) {
            targetPreface = this.currentArticleModel.prefaceId
        }

        var serverPrefaces = config.prefaces
        var serverChilds = serverPrefaces.filter((p) => p.parentPrefaceId != null)
        var serverParents = serverPrefaces.filter((p) => p.parentPrefaceId == null)

        if (serverChilds.length > 0) {
            this.mShowChildPreface = true

            if (targetPreface == null) {
                this.parentPrefaces = serverParents
                this.mParentPreface = serverParents[0].prefaceId
                this.childPrefaces = serverChilds.filter((c) => c.parentPrefaceId === this.mParentPreface)
                this.mChildPreface = this.childPrefaces[0].prefaceId
            } else {
                var tpmodel = serverChilds.find((c) => c.prefaceId === targetPreface?.trim())

                if (tpmodel) {
                    this.parentPrefaces = serverParents
                    this.mParentPreface = tpmodel.parentPrefaceId
                    this.childPrefaces = serverChilds.filter((c) => c.parentPrefaceId === this.mParentPreface)
                    this.mChildPreface = tpmodel.prefaceId
                } else {
                    this.parentPrefaces = serverParents
                    this.mParentPreface = serverParents[0].prefaceId
                    this.childPrefaces = serverChilds.filter((c) => c.parentPrefaceId === this.mParentPreface)
                    this.mChildPreface = this.childPrefaces[0].prefaceId
                }
            }
        } else {
            this.parentPrefaces = serverParents
            this.mParentPreface =
                targetPreface == null ? this.parentPrefaces[0].prefaceId : targetPreface.trim()

            console.log(this.mParentPreface)
        }
    }

    getFieldComponent(field: board.ContentField) {
        switch (field.fieldType) {
            case 'TEXT':
                return TextField
            case 'TEXTAREA':
                return TextAreaField
            case 'DATETIME':
                return DatetimeField
            case 'SELECT':
                return SelectField
            case 'TIMESPAN':
                return TimeSpanField
            case 'MAILSEND':
                return MailSendField
            case 'SELECTUSER':
                return SelectUserField
            case 'CHECKBOX':
                return CheckField
        }
    }

    getImageSrc() {
        var divElem = document.createElement('div')
        divElem.innerHTML = this.mContent

        var src = divElem.querySelector('img')?.src

        if (src) {
            return src
        } else {
            return null;
        }
    }

    loadEditorContent() {
     
        const editor: editor.IEditorProxy = this.$refs.editor as any
        this.mContent = editor.getEditor().getBodyValue();
    }

    getRequestModel(draft: boolean = false) {
        if (this.writeConfig == null) return
        if (this.mUseContent) this.loadEditorContent()

        var fieldValues: article.FieldValueSet[] = []
        var imageSrc = this.getImageSrc()
        var prefaceId = this.mShowChildPreface ? this.mChildPreface : this.mParentPreface

        this.returnValues.forEach((r) => {
            var returnValue = r.returnValue == null ? '' : r.returnValue.toString()
            fieldValues.push({
                fieldId: r.fieldId,
                value: returnValue,
            })
        })

        return <article.ArticleQuerySet>{
            fieldValues: fieldValues,
            isNotice: this.mIsNotice === 'notice',
            boardId: this.currentBoard,
            boardName: this.writeConfig.boardName,
            title: this.mTitle,
            content: this.mContent,
            tags: this.mTags.join(','),
            // user: this.mUseNameless
            //     ? null
            //     : {
            //         userCode: this.user.id,
            //         userName: this.user.displayName,
            //         userName_En: this.user.displayNameEng,
            //         deptName: this.user.deptName,
            //         deptName_En: this.user.deptNameEn,
            //         userJobTitle: this.user.titleName,
            //         userJobTitle_En: this.user.titleNameEn,
            //         userDept: this.user.deptCode,
            //         userMail: this.user.email,
            //         userPhone: `${this.user.officeTel}/${this.user.mobileTel}`,
            //     },
            user: {
                userCode: this.user.id,
                userName: this.user.displayName,
                userName_En: this.user.displayNameEng,
                deptName: this.user.deptName,
                deptName_En: this.user.deptNameEn,
                userJobTitle: this.user.titleName,
                userJobTitle_En: this.user.titleNameEn,
                userDept: this.user.deptCode,
                userMail: this.user.email,
                userPhone: `${this.user.officeTel}/${this.user.mobileTel}`,
            },
            fileCode: this.files.length == 0 ? null : this.mFileCode,
            hasAttachFile: this.files.length == 0 ? false : true,
            hasImage: imageSrc != null ? true : false,
            prefaceId: prefaceId,
            parentArticle: this.writeMode == WriteMode.Reply ? this.parentArticle : null,
            replyLevel: 0,
            imageFilePath: imageSrc,
            isDraft: draft,
            isMailSend: this.writeConfig.useMailSend,
            password: this.mUseNameless ? this.mWritePassword : null,
            etC1: null,
            etC2: null,
            etC3: null,
            etC4: null,
            etC5: null,
            articleVersion: (this.currentArticleModel && this.currentArticleModel.articleVersion ? this.currentArticleModel.articleVersion : 1),
            isSecret: this.mSecret ?? false,
            isNameless: this.mUseNameless
        }
    }

    async checkValidate(article: article.ArticleQuerySet) {
        if (this.mTitle == null || this.mTitle.length == 0) {
            await (window as any).alertAsync(this.$t('M_BB_TitleIsNull').toString())
            return false
        }

        if (this.mUseNameless && this.writeMode != 1 && this.mWritePassword.trim().length == 0) {
            await (window as any).alertAsync(this.$t('글수정_패스워드_입력').toString());
            return false;
        }

        if (this.isFileUploading) {
            await (window as any).alertAsync(this.$t('파일_업로드중').toString())
            return false
        }

        return true
    }

    async onSubmitClick(draft: boolean) {
        var queryModel = this.getRequestModel(draft)

        if (queryModel && await this.checkValidate(queryModel)) {
            this.isLoading = true;

            var requestPromise: Promise<any> = Promise.resolve()

            if (this.writeMode == WriteMode.Write || this.writeMode == WriteMode.Reply) {
                requestPromise = this.createArticle(queryModel);
            } else {
                queryModel.articleId = this.currentArticle
                requestPromise = this.updateArticle(queryModel)
            }

            requestPromise.then((res) => {
                this.isLoading = false
                this.handleClose(true)
            }).catch(err => { this.isLoading = false; });
        }
        // } else {
        //     this.isLoading = false;
        // }
    }

    onLookaheadClick() {
        if (this.writeConfig == null) return

        var lookaheadId = getGUID(false)
        var fieldValues: article.ReadFieldValue[] = []
        var prefaceId = this.mShowChildPreface ? this.mChildPreface : this.mParentPreface
        var prefaceText = this.mShowChildPreface
            ? this.childPrefaces.find((c) => c.prefaceId === prefaceId)?.prefaceText
            : this.parentPrefaces.find((p) => p.prefaceId === prefaceId)?.prefaceText
        var prefaceTextEn = this.mShowChildPreface
            ? this.childPrefaces.find((c) => c.prefaceId === prefaceId)?.prefaceText_En
            : this.parentPrefaces.find((p) => p.prefaceId === prefaceId)?.prefaceText_En

        this.contentFields.forEach((c) => {
            var found = this.returnValues.find((r) => r.fieldId === c.fieldId)

            if (c.fieldValueName == null) {
                if (found && found.returnValue != null) {
                    fieldValues.push({
                        articleId: 0,
                        displayName: c.displayName,
                        displayName_En: c.displayName_En,
                        fieldId: c.fieldId,
                        isHiddenField: c.isHiddenField,
                        isSearchField: c.isSearchField,
                        order: c.order,
                        value: found.returnValue.toString(),
                    })
                }
            }
        })

        // 미리보기를 위해 Content 데이터 재설정
        this.loadEditorContent();

        var mLookahead: article.ArticleDetail = {
            fieldValues: fieldValues,
            permissions: [],
            articleId: 0,
            boarId: this.currentBoard,
            prefaceId: this.mShowChildPreface ? this.mChildPreface : this.mParentPreface,
            prefaceText: prefaceText || '',
            prefaceText_en: prefaceTextEn || '',
            tags: this.mTags.join(','),
            parentArticle: this.parentArticle,
            title: this.mTitle,
            content: this.mContent,
            replyLevel: 0,
            replyOrder: 0,
            replyGroup: 0,
            expiredDate: null,
            createdDate: new Date(),
            createdUser: this.user.personCode,
            createdUserName: this.user.displayName,
            createdUserName_En: this.user.displayNameEng,
            createdPhone: `${this.user.officeTel}/${this.user.mobileTel}`,
            createdMailAddress: this.user.email,
            createdDept: this.user.deptCode,
            createdDeptName: this.user.deptName,
            createdDeptName_En: this.user.deptNameEn,
            createdUserJobTitle: this.user.titleName,
            createdUserJobTitle_En: this.user.titleNameEn,
            updatedUser: null,
            updatedUserName: null,
            updatedUserName_En: null,
            updatedDeptName: null,
            updatedDeptName_En: null,
            updatedUserJobTitle: null,
            updatedUserJobTitle_En: null,
            updatedDate: null,
            isMainNotice: false,
            isNotice: false,
            isNameless: this.mUseNameless,
            isMailSend: false,
            hasAttachFile: this.mFileCode == null ? false : true,
            fileCode: this.mFileCode,
            viewCount: 0,
            recommendCount: 0,
            recommendedDate: null,
            articleStatus: 'N',
            articleSeq: 0,
            commentCount: 0,
            hasImage: false,
            imageFilePath: null,
            communityId: null,
            teamsChannelId: null,
            isDraft: false,
            etC1: null,
            etC2: null,
            etC3: null,
            etC4: null,
            etC5: null,
            articleVersion: 1
        }

        var strArticle = JSON.stringify(mLookahead)
        localStorageHelper.set(`$board/${lookaheadId}/lookahead`, strArticle)

        window.open(
            `${this.popupBaseUrl}readPopup?SYSID=${this.currentBoard}&LOOKAHEAD=${lookaheadId}`,
            'BoardLookahead',
            'width=900,height=760,resizable=yes'
        )
    }

    onCancelClick() {
        this.handleClose(false)
    }

    handleClose(reflesh: boolean) {
        if (reflesh) {
            var eventTarget = this.isModal ? window : window.opener;
            dispatchEventBus(eventTarget, 'boardlist-reload', '')
        }

        if (this.isModal) {
            this.onCloseModal()
        } else {
            window.close()
        }
    }

    onCloseModal() {
        var closePath = this.$route.path.replace('/modal/write', '')
        this.$router.push({
            path: closePath,
            query: {
                SYSID: this.currentBoard.toString(),
            },
        })
    }

    editorChanged(value: string) {
        this.mContent = value;
    }

    closeModal(){
        this.showboardNotice = false
    }

    //게시판명 다국어 처리
    //상단 헤더 표시되는 게시판명
    getBoardName(boardName: string) {
        let boardId = this.$route.query.SYSID as string
        let menu = menuHelper.findOneBySysId(parseInt(boardId), 'BB')
        if (menu) {
            return this.$t(menu.menuName)
        }

    }
}
