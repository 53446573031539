import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import * as accountTypes from '@account/store/types'
import { mapGetters } from 'vuex'
// components
import BoardMain from '../components/board/BoardMain.vue'
import { MasterTemplate } from '@appbase/templates'

@Component({
  components: {
    BoardMain,
    MasterTemplate
  },
  computed: {
    ...mapGetters({
      user: accountTypes.USER,
    }),
  },
})
export default class BoardMaster extends Vue {
  user!: account.User
}
