import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { Component, Prop, Watch } from "vue-property-decorator";
import * as types from "@board/store/types";
import * as accountTypes from "@account/store/types";
import { getProperty, getLangProperty } from "@appbase/util";
import * as dateutil from "@appbase/util/date-helper";
import { open, OpenType } from "@board/open-handler";

import { LoadingBar } from "@appbase/components/loading";
import api from '@board/api';
import menuHelper from '@appbase/util/menu-helper'

var ids: common.BoardFieldSetting = getProperty(
  window,
  "eumBoardIds"
) as common.BoardFieldSetting;

import { debounce } from "@appbase/util"; // boardconfig 가 중복으로 변경됨

@Component({
  components: {
    LoadingBar,
  },

  computed: mapGetters({
    isLoading: types.BOARD_LOADDING,
    listFields: types.FETCH_LISTFIELD,
    articleList: types.FETCH_ARTICLES,
    popupBaseUrl: types.POPUP_BASEURL,
    pageSet: types.FETCH_PAGESET,
    boardConfig: types.BOARD_CONFIG,
    user: accountTypes.USER,
    userCurrent: accountTypes.CURRENT,
  }),

  methods: mapActions({
    fetchArticle: types.FETCH_ARTICLES,
    fetchPageSet: types.FETCH_PAGESET,
  }),
})
export default class BoardList extends Vue {
  @Prop({ type: Number }) currentPage!: number;
  @Prop({ type: Boolean }) adminMode!: boolean;
  @Prop({ type: Number }) viewCount!: number;

  fetchArticle!: (
    searchset: article.SearchSet
  ) => Promise<article.PagedData<article.Article>>;
  fetchPageSet!: (pageSet: boardModule.state.PageSet) => void;

  isLoading!: boolean;
  listFields!: board.ListField[];
  articleList!: Array<article.Article>;
  pageSet!: boardModule.state.PageSet;
  popupBaseUrl!: string;
  user!: account.User;
  boardConfig!: board.BoardItem;
  userCurrent!: account.TCurrent

  itemsPerPage: number = 15;
  currentField: number = 0;
  sortOrder: boolean = false;
  searchItems: number = 0;
  currentPreface: string | null = null;

  hasReadPermission: boolean = false;

  @Watch("boardConfig")
  onBoardChanged(newVal: board.BoardItem) {
    // this.loadPermission();
    // this.fetchPageSet(<boardModule.state.PageSet>{
    //     boardId: newVal.boardId,
    //     currentPage: 1,
    //     searchField: 0,
    //     searchText: null,
    //     preface1: null,
    //     preface2: null
    // });

    this.boardConfigchangeAction(newVal.zIsHelpDesk, newVal.boardId, this);
  }

  //2022-08-08 게시판 변경 시 이전 게시판 리스트 목록이 표시되는 증상이 있음
  //해당 증상을 보완하기 위해 코드 수정 함
  boardConfigchangeAction = debounce(
    (isHelpdesk: boolean, boardId: number, _this: any) => {
      const boardInitFunc = () => {
        _this.loadPermission();
        _this.fetchPageSet(<boardModule.state.PageSet>{
          boardId: boardId,
          currentPage: 1,
          searchField: 0,
          searchText: null,
          preface1: null,
          preface2: null,
        });
      };

      _this.isHelpdesk(isHelpdesk, () => {
        boardInitFunc();
      }); //지누스  헬프데스크 게시판인지 체크 후 이후 로직 수행
      // if(isHelpdesk) return

      // boardInitFunc();
    },
    500
  );

  @Watch("pageSet")
  async onPageSetChange(newVal: boardModule.state.PageSet) {
    this.itemsPerPage =
      this.boardConfig.itemsPerPage == null
        ? this.itemsPerPage
        : this.boardConfig.itemsPerPage;

    if (this.boardConfig.zIsHelpDesk) {
      this.itemsPerPage = this.viewCount;
    }

    var searchSet = <article.SearchSet>{
      userCode: this.user.id,
      boardId: newVal.boardId,
      page: newVal.currentPage,
      itemsPerPage: this.itemsPerPage,
      searchField: newVal.searchField,
      searchFieldValue: newVal.searchText,
      prefaceId1: newVal.preface1,
      prefaceId2: newVal.preface2,
      sortField: this.currentField,
      sortOrder: this.sortOrder,
      includeContent: false,
      useNotice: this.boardConfig.usePrimaryNotice,
      isHelpdesk: this.boardConfig.zIsHelpDesk ? true : false, //2022-06-07 헬프데스크 병합 기준 파람 추가
      helpdeskCategory: null,
      helpdeskStatus: null,
    };
    const ConvertDate = (date: Date) => {
      return dateutil.uGetDateFormat("yyyy-MM-DD", dateutil.uGetDateOnly(date));
    };

    if (this.boardConfig.zIsHelpDesk == true) {
      searchSet.helpdeskCategory = newVal.helpdeskCategory;
      searchSet.helpdeskStatus = newVal.helpdeskStatus;
      searchSet.helpdeskDateType = newVal.helpdeskDateType
        ? newVal.helpdeskDateType
        : 0;
      searchSet.startDate = newVal.startDate
        ? newVal.startDate
        : ConvertDate(dateutil.uGetDate(-3, new Date(), "month"));
      searchSet.endDate = newVal.endDate
        ? newVal.endDate
        : ConvertDate(new Date());
    }

    //this.debounceFetchArticle(searchSet,newVal.currentPage, this)
    // 2023-09-12 : 익명 게시판의 경우 Super 관리자인 경우에도 메뉴 권한그룹에 속해있지 않는다면 게시글을 조회할 수 없도록 수정
    var isCustomBoard: boolean = false;
    var customBoardList = this.getAppConfig("Z_Board_CustomNameless_List");
    if (customBoardList){
      var jsonData = JSON.parse(customBoardList);
      var jObj: Array<any> = (jsonData && jsonData["NamelessBoardList"] && jsonData["NamelessBoardList"].length > 0 ? jsonData["NamelessBoardList"] : null);
      var customBoardInfo = jObj.find(x => x.BoardId == this.boardConfig.boardId.toString());
      if (customBoardInfo){
        isCustomBoard = true;
      }
    }

    if (isCustomBoard){
      // MenuId 값과 BoardId 매핑 값이 맞는지 체크
      const { MENUID } = this.$route.query;
      if (MENUID) {
        var menuInfo = menuHelper.findOne(Number(MENUID));
        if (menuInfo) {
          if (menuInfo.systemId == this.boardConfig.boardId){
            // 정상적인 접근인 경우
            api.board.getCustomBoardAuthCheck(menuInfo.menuId, this.user.personCode).then(async (res) => {
              if (res){
                // 권한이 있는 경우
                this.fetchArticle(searchSet).then((res) => {
                  this.searchItems = res.totalCount;
                  this.$emit(
                    "articlesChanged",
                    this.itemsPerPage,
                    res.totalCount,
                    newVal.currentPage,
                    res.data.length
                  );
                });
              }
              else {
                // 권한이 없는 경우
                await (window as any).alertAsync(this.$t('M_BB_NoReadPermission').toString());
                this.$router.go(-1);
              }
            });
          }
          else {
            // 비정상적인 접근인 경우
            await (window as any).alertAsync(this.$t('잘못된_접근').toString());
            this.$router.go(-1);
          }
        } 
      }
    }
    else {
      this.fetchArticle(searchSet).then((res) => {
        this.searchItems = res.totalCount;
        this.$emit(
          "articlesChanged",
          this.itemsPerPage,
          res.totalCount,
          newVal.currentPage,
          res.data.length
        );
      });
    }
  }

  created() {
    if (this.boardConfig != null) {
      this.onBoardChanged(this.boardConfig);
      //지누스  헬프데스크 게시판인지 체크 후 이후 로직 수행
      //this.isHelpdesk()
    }
  }

  isHelpdesk(isHelpdesk?: boolean, callback?: () => void) {
    if (callback) callback();
    //헬프데스크에서는 boardlist.ts 를 상속받아 사용하기 때문에 boardlist 에서만 작동해야됨
    if (this.$route.name == "boardmodule-helpdesk") return;

    if (isHelpdesk ? isHelpdesk : this.boardConfig.zIsHelpDesk == true) {
      this.$router
        .push({
          name: "boardmodule-helpdesk",
          query: this.$route.query,
        })
        .catch(() => {});
    }
  }

  loadPermission() {
    /*TODO 권한설정*/
    // if(this.menus != null && this.board != null) {
    //     var permission = this.menus.find(m => m.menuId == this.board.menuId);
    //     this.hasReadPermission = permission != null && permission.read;
    // }
    this.hasReadPermission = true;
  }

  getlistFieldName(field: board.ListField) {
    return getLangProperty(field, "displayName");
  }

  getArticleFieldValue(article: article.Article, field: board.BoardField) {
    var returnValue = null;
    if (field.fieldValueName != null) {
      if (field.fieldId === ids.ID_TITLEFIELD) {
        article.commentCount > 0
          ? (returnValue = `${article.title}`)
          : (returnValue = article.title);
      } else if (field.fieldId == ids.ID_WRITERFIELD) {
        returnValue = this.boardConfig.useNameless
          ? // this.$t('W_BB_Anonymous') : getLangProperty(article, "createdUserName");
            this.$t("W_BB_Anonymous")
          : article.createdUserName;
      } else if (field.fieldId == ids.ID_PREFACE) {
        returnValue = getLangProperty(article, field.fieldValueName);
      } else {
        returnValue = getProperty(article, field.fieldValueName);
      }
    } else {
      var FieldValue = article.fieldValues.find(
        (f) => f.fieldId == field.fieldId
      );
      returnValue = FieldValue?.value;
    }

    if (returnValue == null) {
      return (returnValue = "");
    } else if (field.fieldType === "DATETIME") {
      var displayDate = new Date(returnValue.toString());
      return dateutil.uGetDateFormat("yyyy-MM-DD", displayDate);
    } else if (field.fieldType === "SELECT") {
      var jObject = JSON.parse(returnValue.toString());
      return jObject.TEXT;
    } else if (field.fieldId === ids.ID_VIEWFIELD) {
      return returnValue;
    } else {
      return returnValue;
    }
  }

  onClickSort(field: number) {
    this.fetchPageSet(<boardModule.state.PageSet>{
      boardId: this.boardConfig.boardId,
      currentPage: 1,
      searchField: 0,
      searchText: null,
      preface1: this.pageSet.preface1,
      preface2: this.pageSet.preface2,
      useNotice: this.pageSet.useNotice,
    });

    this.currentField = field;
    this.sortOrder == false
      ? (this.sortOrder = true)
      : (this.sortOrder = false);
  }

  getFieldAlign(field: board.ListField) {
    if (field.textAlign == null) {
      return { "align-center": true, "show-overflow": this.isWriter(field) };
    } else {
      var key = `align-${field.textAlign}`;
      var classObject = <common.DynamicClass>{};
      classObject[key] = true;
      classObject["show-overflow"] = this.isWriter(field);
      return classObject;
    }
  }

  getHeaderWidth(field: board.ListField) {
    if (field.fieldId === ids.ID_TITLEFIELD) {
      return null;
    } else {
      return `${field.width}px`;
    }
  }

  getPreface(article: article.Article) {
    return article.prefaceText == null ? "" : `[${article.prefaceText}]`;
  }

  isTitle(field: board.ListField) {
    if (field.fieldId == ids.ID_TITLEFIELD) {
      return true;
    } else {
      return false;
    }
  }

  isWriter(field: board.ListField) {
    if (field.fieldId == ids.ID_WRITERFIELD) {
      return true;
    } else {
      return false;
    }
  }

  setTitleCss(field: board.ListField) {
    if (field.fieldId == 1) {
      return { "board-list": true };
    } else {
      return null;
    }
  }

  getTitlePadding(article: article.Article) {
    if (article.replyLevel > 0) {
      var padding = 20 * article.replyLevel;
      return `${padding}px`;
    } else {
      return "0px";
    }
  }

  showreplyLevel(article: article.Article) {
    if (article.parentArticle != null) {
      return true;
    } else {
      return false;
    }
  }

  showDraft(article: article.Article) {
    if (article.isDraft == true) {
      return true;
    } else {
      return false;
    }
  }

  showNotice(field: board.ListField, article: article.Article) {
    if (field.fieldValueName == "articleSeq") {
      if (article.isNotice == true && this.boardConfig.usePrimaryNotice) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getNoticeCss(article: article.Article) {
    if (article.isNotice == true && this.boardConfig.usePrimaryNotice) {
      return true;
    } else {
      return false;
    }
  }

  showNewIcon(article: article.Article) {
    var oneDay = 1000 * 60 * 60 * 24;
    var createdDate = new Date(article.createdDate.toString());
    var today = new Date();

    var timeSpan = today.getTime() - createdDate.getTime();

    if (timeSpan <= oneDay) {
      return true;
    } else {
      return false;
    }
  }

  showCommentCount(article: article.Article) {
    if (this.boardConfig.useComment && article.commentCount > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkArticleExpired(expiredDate: Date | null) {
    if (expiredDate == null) {
      return false;
    } else {
      var tDate = new Date();
      var today = dateutil.uGetDateFormat("yyyy-MM-DD", tDate);

      var eDate = new Date(expiredDate);
      var articlceDate = dateutil.uGetDateFormat("yyyy-MM-DD", eDate);
      return articlceDate < today;
    }
  }

  async goDetailPage(article: article.ArticleDetail) {
    if (this.checkArticleExpired(article.expiredDate)) {
      await (window as any).alertAsync(this.$t("M_BB_ExpiredPost").toString());
      return;
    }

    //게시글 읽기 권한
    // open(this.isTeams || !this.boardConfig.usePopup ?
    //     OpenType.ModalRead : OpenType.PopupRead,
    if (this.hasReadPermission) {
      open(
        this.isTeams || !this.boardConfig.usePopup
          ? OpenType.ModalRead
          : OpenType.PopupRead,
        {
          boardId: this.boardConfig.boardId,
          articleId: article.articleId,
          baseUrl: this.popupBaseUrl,
        }
      );
    } else {
      await (window as any).alertAsync(this.$t("M_BB_NoReadPermission").toString());
      return;
    }
  }
}
