import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex'
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import * as types from '@board/store/types'
import * as accountTypes from '@account/store/types'
import { getProperty, getLangProperty } from '@appbase/util';
import * as dateutil from '@appbase/util/date-helper';
import { open, OpenType } from '@board/open-handler'; 
import localStorageHelper from '@appbase/util/local-storage-helper';
import CheckBox from '@board/components/common/CheckBox.vue'
import api from '@board/api';

var ids: common.BoardFieldSetting = getProperty(window, 'eumBoardIds') as common.BoardFieldSetting;

@Component({
    components: {
        CheckBox
    },

    computed: mapGetters({
        listFields: types.FETCH_LISTFIELD,
        articleList: types.FETCH_ARTICLES,
        popupBaseUrl: types.POPUP_BASEURL,
        pageSet: types.FETCH_PAGESET,
        user: accountTypes.USER
    }),

    methods: mapActions({
        fetchArticle: types.FETCH_ADMINARTICLES,
        fetchPageSet: types.FETCH_PAGESET,
        fetchArticleEmpty: types.FETCH_ARTICLESEMPTY
    })
})

export default class BoardAdminList extends Vue {
    @Prop({ type: Number }) currentPage!: number;
    @Prop({ type: Object }) board!: board.BoardItem;
    @Prop({ type: Boolean }) adminMode!: boolean;
    @PropSync('selectedArticles', { type: Array }) selectedArticlesSync!: article.Article[];

    fetchArticle!: (searchset: article.SearchSet) => Promise<article.PagedData<article.Article>>;
    fetchPageSet!: (pageSet: boardModule.state.PageSet) => void;    
    fetchArticleEmpty!: () => void

    listFields!: board.ListField[];
    articleList!: Array<article.Article>;
    pageSet!: boardModule.state.PageSet;
    popupBaseUrl!: string;
    user!: account.User;

    itemsPerPage: number = 15;  
    useSelect: boolean = false;   
    currentField: number = 0;
    sortOrder: boolean = false;
    searchItems: number = 0;
    currentPreface: string | null = null;

    hasReadPermission: boolean = false;
    selectAll: boolean = false;
    selectedModel: boolean[] = [];

    @Watch('board')
    onBoardChanged(newVal: board.BoardItem) {
        this.loadPermission();
        this.fetchPageSet(<boardModule.state.PageSet>{
            boardId: newVal.boardId,
            currentPage: 1,
            searchField: 0,
            searchText: null,
            preface1: null,
            preface2: null
        });
    }

    @Watch('pageSet')
    onPageSetChange(newVal: boardModule.state.PageSet, oldVal: boardModule.state.PageSet) {
        this.useSelect = this.board.useSelect;
        this.itemsPerPage = this.board.itemsPerPage == null ? this.itemsPerPage : this.board.itemsPerPage;

        var searchSet = <article.SearchSet>{
            userCode: this.user.id,
            boardId: newVal.boardId,
            page: newVal.currentPage,
            itemsPerPage: this.itemsPerPage,
            searchField: newVal.searchField,
            searchFieldValue: newVal.searchText,
            prefaceId1: newVal.preface1,
            prefaceId2: newVal.preface2,
            sortField: this.currentField,
            sortOrder: this.sortOrder,
            includeContent: false,
            useNotice: this.board.usePrimaryNotice
        }

        // 2023-09-12 : 익명 게시판의 경우 Super 관리자인 경우에도 메뉴 권한그룹에 속해있지 않는다면 게시글을 조회할 수 없도록 수정
        var isCustomBoard: boolean = false;
        var customBoardList = this.getAppConfig("Z_Board_CustomNameless_List");
        if (customBoardList){
            var jsonData = JSON.parse(customBoardList);
            var jObj: Array<any> = (jsonData && jsonData["NamelessBoardList"] && jsonData["NamelessBoardList"].length > 0 ? jsonData["NamelessBoardList"] : null);
            var customBoardInfo = jObj.find(x => x.BoardId == newVal.boardId.toString());
            if (customBoardInfo){
              isCustomBoard = true;
            }
        }

        if (isCustomBoard) {
            api.board.getCustomBoardAuthCheckToBoardId(newVal.boardId, this.user.personCode).then(async (res) => {
                if (res) {
                    // 권한이 있는 경우
                    this.fetchArticle(searchSet).then(res => {
                        var articleId = this.articleList.map(i => i.articleId)            
                        localStorageHelper.set('articleId', articleId);
                        this.searchItems = res.totalCount;
                        this.selectAll = false;
                        this.selectedModel = this.articleList.map(a => false);
                        this.$emit('articlesChanged', this.itemsPerPage, res.totalCount, newVal.currentPage, res.data.length);
                    });
                }
                else {
                  // 권한이 없는 경우
                  await (window as any).alertAsync(this.$t('M_BB_NoReadPermission').toString());
                  this.fetchArticleEmpty();
                  this.$emit('articlesChanged', this.itemsPerPage, 0, newVal.currentPage, 0);
                }
            });
        }
        else {
            this.fetchArticle(searchSet).then(res => {
                var articleId = this.articleList.map(i => i.articleId)            
                localStorageHelper.set('articleId', articleId);
                this.searchItems = res.totalCount;
                this.selectAll = false;
                this.selectedModel = this.articleList.map(a => false);
                this.$emit('articlesChanged', this.itemsPerPage, res.totalCount, newVal.currentPage, res.data.length);
            })
        }
    }

    getStatusText(article: article.Article) {
        // return article.articleStatus === 'N' ? 
        //     this.$t('정상').toString() : 
        //     this.$t('삭제').toString();

        var displayText = '';
        if (article.articleStatus === 'D'){
            displayText = this.$t('삭제').toString();
        }
        else {
            if (article.expiredDate && new Date(article.expiredDate) < new Date()){
                displayText = this.$t('W_Common_Expired').toString();
            }
            else {
                displayText = this.$t('정상').toString();
            }
        }

        return displayText;
    }

    created() {
        if (this.board != null) {
            this.onBoardChanged(this.board);
        }
    }

    loadPermission() {
        /*TODO 권한설정*/
        // if(this.menus != null && this.board != null) {
        //     var permission = this.menus.find(m => m.menuId == this.board.menuId);
        //     this.hasReadPermission = permission != null && permission.read;        
        // }        
        this.hasReadPermission = true;
    }

    getlistFieldName(field: board.ListField) {
        return getLangProperty(field, 'displayName');        
    }

    getArticleFieldValue(article: article.Article, field: board.BoardField) {        
        var returnValue = null;
        if (field.fieldValueName != null) {
            if (field.fieldId === ids.ID_TITLEFIELD) {
                article.commentCount > 0 ? returnValue = `${article.title}` : returnValue = article.title;
            } else if(field.fieldId == ids.ID_WRITERFIELD) {               
                returnValue = this.board.useNameless ? 
                        this.$t('W_BB_Anonymous') : getLangProperty(article, "createdUserName");                                
            } else if(field.fieldId == ids.ID_PREFACE) {
                returnValue = getLangProperty(article, field.fieldValueName);
            } else {
                returnValue = getProperty(article, field.fieldValueName);
            }
        }
        else {
            var FieldValue = article.fieldValues.find(f => f.fieldId == field.fieldId);
            returnValue = FieldValue?.value;
        }

        if (returnValue == null) {
            return returnValue = "";
        } else if (field.fieldType === 'DATETIME') {
            var displayDate = new Date(returnValue.toString());
            return dateutil.uGetDateFormat('yyyy-MM-DD', displayDate);
        } else if (field.fieldType === 'SELECT') {
            var jObject = JSON.parse(returnValue.toString());
            return jObject.TEXT;
        } else if (field.fieldId === ids.ID_VIEWFIELD) {
            return returnValue;
        } else {
            return returnValue;
        }
    }

    onClickSort(field: number) {
        this.fetchPageSet(<boardModule.state.PageSet>{
            boardId: this.board.boardId,
            currentPage: 1,
            searchField: 0,
            searchText: null,
            preface1: this.pageSet.preface1,
            preface2: this.pageSet.preface2,
            useNotice: this.pageSet.useNotice
        });

        this.currentField = field;
        this.sortOrder == false ? this.sortOrder = true : this.sortOrder = false;
    }

    getFieldAlign(field: board.ListField) {
        if (field.textAlign == null) {
            return { 'align-center': true, 'show-overflow': this.isWriter(field) }
        } else {
            var key = `align-${field.textAlign}`;
            var classObject = <common.DynamicClass>{};
            classObject[key] = true;
            classObject['show-overflow'] = this.isWriter(field);
            return classObject;
        }
    }

    getHeaderWidth(field: board.ListField) {
        if (field.fieldId === ids.ID_TITLEFIELD) {
            return null
        } else {
            return `${field.width}px`
        }
    }

    getPreface(article: article.Article) {
        return article.prefaceText == null ? "" : `[${article.prefaceText}]`;
    }

    isTitle(field: board.ListField) {
        if (field.fieldId == ids.ID_TITLEFIELD) {
            return true;
        } else {
            return false;
        }
    }

    isWriter(field: board.ListField) {
        if (field.fieldId == ids.ID_WRITERFIELD) {
            return true;
        } else {
            return false;
        }
    }

    setTitleCss(field: board.ListField) {
        if (field.fieldId == 1) {
            return { 'board-list': true }
        }
        else {
            return null;
        }
    }

    getTitlePadding(field: board.ContentField, article: article.Article) {
        if (field.fieldValueName == 'title' && article.replyLevel > 0) {
            var padding = 20 * article.replyLevel
            return `${padding}px`;
        } else {
            return "0px";
        }
    }

    showreplyLevel(listField: board.ListField, article: article.Article) {
        if (article.parentArticle != null && this.isTitle(listField)) {
            return true
        } else {
            return false
        }
    }

    showDraft(article: article.Article) {
        if (article.isDraft == true) {
            return true
        } else {
            return false
        }
    }

    showNotice(field: board.ListField, article: article.Article) {
        if (field.fieldValueName == 'articleSeq') {
            if (article.isNotice == true && this.board.usePrimaryNotice) {
                return true
            } else {
                return false
            }
        } else {
            return false;
        }
    }

    getNoticeCss(article: article.Article) {
        if (article.isNotice == true && this.board.usePrimaryNotice) {
            return true
        } else {
            return false
        }
    }

    showNewIcon(article: article.Article) {
        var oneDay = 1000 * 60 * 60 * 24;
        var createdDate = new Date(article.createdDate.toString());
        var today = new Date();

        var timeSpan = today.getTime() - createdDate.getTime();


        if (timeSpan <= oneDay) {
            return true;
        } else {
            return false;
        }
    }

    showCommentCount(field: board.ListField, article: article.Article) {
        if (this.isTitle(field)) {
            if (this.board.useComment && article.commentCount > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    checkArticleExpired(expiredDate: Date | null) {
        if(expiredDate == null) {
            return false;
        } else {
            var tDate = new Date();
            var today = dateutil.uGetDateFormat('yyyy-MM-DD', tDate);

            var eDate = new Date(expiredDate);
            var articlceDate = dateutil.uGetDateFormat('yyyy-MM-DD', eDate);
            return articlceDate < today
        }
    }

    async goDetailPage(article: article.ArticleDetail) {    
        if(this.checkArticleExpired(article.expiredDate)) {
            await (window as any).alertAsync(this.$t("M_BB_ExpiredPost").toString());
            return;
        }
        
        if (this.hasReadPermission) {            
            open(!this.isTeams && ( this.board.usePopup || this.adminMode ) ? 
                OpenType.PopupRead : OpenType.ModalRead,
                {
                    boardId: this.board.boardId,
                    articleId: article.articleId,
                    baseUrl: this.popupBaseUrl
                });
        } else {
            await (window as any).alertAsync(this.$t('M_BB_NoReadPermission').toString());
            return;
        }
    }

    selectChangeAll() {
        this.selectedModel = this.selectedModel.map(s => this.selectAll); 
        this.syncSelectChanges();    
    }

    selectChange(article: article.Article, idx: number) {
        this.syncSelectChanges();
    }

    syncSelectChanges() {
        var syncList: article.Article[] = [];
        this.articleList.forEach((a, i) => {           
            if(this.selectedModel[i]) {
                syncList.push(a);
            }                 
        });

        this.selectedArticlesSync = syncList;      
    }
}