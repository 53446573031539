var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isLoading)?_c('LoadingBar',{staticStyle:{"height":"400px"}}):_c('div',{attrs:{"id":"boardList"}},[_c('div',{staticClass:"grd-wrap",staticStyle:{"display":"block"}},[_c('table',{staticClass:"grd ellipsis",attrs:{"width":"100%;"}},[_c('colgroup',[_c('col',{attrs:{"width":"60`px"}}),_c('col',{attrs:{"width":"120px"}}),_c('col',{attrs:{"width":"100px"}}),_c('col',{attrs:{"width":"100px"}}),_c('col',{attrs:{"width":"120px"}}),_c('col',{attrs:{"width":"130px"}}),_c('col',{attrs:{"width":"*"}}),_c('col',{attrs:{"width":"120px"}}),_c('col',{attrs:{"width":"120px"}}),_c('col',{attrs:{"width":"120px"}}),_c('col',{attrs:{"width":"100px"}}),_c('col',{attrs:{"width":"100px"}})]),_c('thead',[_c('th',[_vm._v(_vm._s(_vm.$t("ID")))]),_c('th',[_vm._v(_vm._s(_vm.$t("LR_티켓_번호")))]),_c('th',[_vm._v(_vm._s(_vm.$t("LR_긴급도")))]),_c('th',[_vm._v(_vm._s(_vm.$t("LR_대분류")))]),_c('th',[_vm._v(_vm._s(_vm.$t("LR_중분류")))]),_c('th',[_vm._v(_vm._s(_vm.$t("LR_소분류")))]),_c('th',[_vm._v(_vm._s(_vm.$t("제목")))]),_c('th',[_vm._v(_vm._s(_vm.$t("LR_상태")))]),_c('th',[_vm._v(_vm._s(_vm.$t("LR_담당자")))]),_c('th',[_vm._v(_vm._s(_vm.$t("작성자")))]),_c('th',[_vm._v(_vm._s(_vm.$t("작성일")))]),_c('th',[_vm._v(_vm._s(_vm.$t("LR_DUE_BY")))])]),_c('tbody',_vm._l((_vm.articleList),function(article){return _c('tr',{key:article.articleId},[_c('td',[_vm._v(" "+_vm._s(article.helpdesk ? article.helpdesk.helpdeskId : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(article.helpdesk ? article.helpdesk.ticketNo : "")+" ")]),_c('td',[_c('span',{staticClass:"badge flex-shrink-0",class:[
                article.helpdesk
                  ? _vm.getServerityCss(article.helpdesk.serverity)
                  : '',
              ]},[_vm._v(_vm._s(article.helpdesk ? article.helpdesk.serverity : ""))])]),_c('td',[_c('span',{class:[
                article.helpdesk ? 'badge flex-shrink-0 badge-green' : '',
              ]},[_vm._v(_vm._s(article.helpdesk ? _vm.convertHelpdeskCategory(article.helpdesk.categoryLv1Id) : ""))])]),_c('td',[_c('span',{class:[
                article.helpdesk ? 'badge flex-shrink-0 badge-green' : '',
              ]},[_vm._v(_vm._s(article.helpdesk ? _vm.convertHelpdeskCategory(article.helpdesk.categoryLv2Id) : ""))])]),_c('td',[_c('span',{class:[
                article.helpdesk ? 'badge flex-shrink-0 badge-green' : '',
              ],attrs:{"title":article.helpdesk
                  ? _vm.convertHelpdeskCategory(article.helpdesk.serviceTypeId)
                  : ``}},[_vm._v(_vm._s(article.helpdesk ? _vm.convertHelpdeskCategory(article.helpdesk.serviceTypeId) : ""))])]),_c('td',{class:{ 'align-center': true }},[_c('div',{staticClass:"dp-flex"},[_c('div',{staticClass:"ellipsis"},[_c('a',{staticClass:"link ellipsis",class:{
                    'ft-red': _vm.getNoticeCss(article),
                  },style:({
                    'padding-left': _vm.getTitlePadding(article),
                  }),attrs:{"title":article.title},on:{"click":function($event){return _vm.goDetailPage(article)}}},[(_vm.showreplyLevel(article))?_c('i',{staticClass:"icon-fux-board-reply m-r-xs"}):_vm._e(),_vm._v(_vm._s(article.title)+" ")])]),_c('div',{staticClass:"flex-shrink-0 m-l-xs dp-flex"},[(_vm.showCommentCount(article))?_c('span',{staticClass:"ft-s ft-red"},[_vm._v(_vm._s(`(${article.commentCount})`))]):_vm._e(),(article.hasAttachFile)?_c('i',{staticClass:"icon-fux-attach"}):_vm._e(),(_vm.showNewIcon(article))?_c('span',{staticClass:"new"},[_vm._v("N")]):_vm._e()])])]),_c('td',[_c('a',{staticClass:"no-deco",attrs:{"title":article.helpdesk ? article.helpdesk.status : ''}},[_vm._v(_vm._s(article.helpdesk ? article.helpdesk.status : ""))])]),_c('td',[_c('a',{staticClass:"no-deco",attrs:{"title":article.helpdesk ? article.helpdesk.managerDisplayName : ''}},[_vm._v(_vm._s(article.helpdesk ? article.helpdesk.managerDisplayName : ""))])]),_c('td',[_c('a',{staticClass:"no-deco",attrs:{"title":_vm.getArticleFieldValue(
                  article,
                  _vm.findFieldByName('createdUserName')
                )}},[_vm._v(_vm._s(_vm.getArticleFieldValue( article, _vm.findFieldByName("createdUserName") )))])]),_c('td',[_vm._v(" "+_vm._s(_vm.getArticleFieldValue(article, _vm.findFieldByName("createdDate")))+" ")]),_c('td',[_vm._v(" "+_vm._s(article.helpdesk ? article.helpdesk.dueByDate == null ? "" : _vm.convertDatetime(article.helpdesk.dueByDate) : "")+" ")])])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchItems == 0),expression:"searchItems == 0"}],staticClass:"not-found"},[_c('img',{staticClass:"m-b-l",attrs:{"src":"/cm/gres/faux/images/no_data.png"}}),_c('span',{staticClass:"ft-gray"},[_vm._v(_vm._s(_vm.$t("검색_결과_없음")))])]),_c('router-view',{key:_vm.$route.fullPath})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }