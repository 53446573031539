import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import BoardWriteBase, { WriteMode } from '@board/components/board/BoardWrite'
import api from '@board/api'
import HelpdeskCategories from './HelpdeskCategories.vue'
import HelpdeskComponent from './HelpdeskComponent.vue'
import HelpdeskComponentTs from './HelpdeskComponent'
import { HelpdeskServerityType, HelpdeskStatus } from '@board/types/helpdesk'
import * as permissionHelper from '@appbase/util/permission-helper'
import menuHelpder from '@appbase/util/menu-helper'
import * as dateutil from '@appbase/util/date-helper'
import { getGUID } from '@appbase/util/guid'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import modalHelper from '@appbase/util/modal-helper';
import { openPopup } from '@appbase/util';
import OrgModalView from '@orgmap/views/OrgmapModalView.vue'
import { createEventBus, getDispatchData, removeEventBus } from '@appbase/util/eventBus'
import HelpdeskServerity from './HelpdeskServerity.vue'
import { ModalLayout } from '@appbase/templates'

export interface TWishContainerOptionItem {
    id: number
    name: string
    height: string
    items?: orgmap.TWishContainerItem[]
}
export interface TWishContainerOptions {
    addableDept: boolean,
    single: boolean,
    containers: TWishContainerOptionItem[]
}

export interface TWishItemPropsOptions {
    props: string[]
}

const EVENT_ORG_POPUP = '$board/helpdesk/org/popup/alarmtarget'

@Component({
    components: {
        HelpdeskServerity,
        HelpdeskCategories,
        HelpdeskComponent,
        DatePicker,
        ModalLayout
    },
})
export default class HelpdeskWrite extends BoardWriteBase {
    selectedCategoryValue: helpdesk.CategoryValue | null = null

    helpdeskCaseData: helpdesk.RequestHelpdeskCase =
        {} as helpdesk.RequestHelpdeskCase
    isHelpdeskAdmin: boolean = false

    isLoadedHelpdeskData: boolean = false

    selectedServerity: HelpdeskServerityType = HelpdeskServerityType.Low

    //안내문 모달
    showHelpdeskNotice : boolean = false
    helpdeskNoticeTemplate : string = ""

    isUseContentNotice : boolean = false
    ContentNoticeTemplate: string = ""

    // 완료요청일
    reqCompleteDate: string = ""
    // 알림수신자지정
    alarmTargetDisplayInfo: string = ""
    alarmTargetPersonCode: string = ""
    
    created() {
        this.initHelpdeskNotice()
        this.initPermission()
        this.initHelpdeskCaseData()

        createEventBus(window, EVENT_ORG_POPUP, this.handlePopupResult)

        //2022-08-03 body 에 스크롤이 2중으로 생기는 문제가 있음
        //강제로 body 에 생성되는 스크롤 hidden 처리
        //야매로 처리한거라 기억하고 있어야됨        
        this.$nextTick(()=>{
            window.document.body.className="overflow-hidden"
        })
    }

 
    //헬프데스크 안내문
    initHelpdeskNotice(){
        let boardId = this.$route.query.SYSID
        if(boardId){
            // api.helpdesk.getHelpdeskNotice(parseInt(<string>boardId)).then(result=>{
            //     debugger
            //     if(result.useNotice==true){
            //         this.showHelpdeskNotice = true
            //         this.helpdeskNoticeTemplate = result.noticeTemplate
            //     }
            // })

            api.helpdesk.getHelpdeskNotiList(parseInt(<string>boardId)).then(res => {
                if (res){
                    var item = res.find(x => x.notiType == 'P' && x.useNotice == true);
                    if (item){
                        this.showHelpdeskNotice = true;
                        this.helpdeskNoticeTemplate = item.noticeTemplate;
                    }

                    var item2 = res.find(x => x.notiType == 'C' && x.useNotice == true);
                    if (item2){
                        this.isUseContentNotice = true;
                        this.ContentNoticeTemplate = item2.noticeTemplate;
                    }
                }
            });
        }
    }

    //헬프데스크 데이터 초기화 기본 값 작성
    initHelpdeskCaseData() {
        // Write = 0,
        // Edit = 1,
        // Reply = 2,
        if (this.writeMode == WriteMode.Edit) {
            api.helpdesk.getHelpdeskCase(this.currentArticle).then((result) => {
                if (result.detail != null) {
                    this.helpdeskCaseData = result

                    //상태값 초기화
                    if (typeof result.detail.status == 'string') {
                        switch (result.detail.status) {
                            case 'Registration':
                                result.detail.status = 0
                                break
                            case 'Receive':
                                result.detail.status = 1
                                break
                            case 'Assign':
                                result.detail.status = 2
                                break
                            case 'Completed':
                                result.detail.status = 3
                                break
                            case 'Reject':
                                result.detail.status = 4
                                break
                            case 'Hold':
                                result.detail.status = 5
                                break
                        }
                    }
 
                    //카테고리 초기화
                    this.selectedCategoryValue = {
                        lv1: result.detail.categoryLv1Id,
                        lv2: result.detail.categoryLv2Id,
                        service: result.detail.serviceTypeId,
                        formHtml: ''
                    }

                    //serverity
                    if (typeof result.detail.serverity == 'string') {
                        switch (result.detail.serverity) {
                            case 'None':
                                result.detail.serverity = 0
                                break
                            case 'Low':
                                result.detail.serverity = 1
                                break
                            case 'Moderate':
                                result.detail.serverity = 2
                                break
                            case 'High':
                                result.detail.serverity = 3
                                break
                            case 'Critical':
                                result.detail.serverity = 4
                                break
                        }
                    }
                    this.selectedServerity = result.detail
                        .serverity as HelpdeskServerityType

                    //첨부파일 관련
                    //this.mFileCode = result.detail.fileCode
                    
                    if (result.detail.requestCompleteDate) this.reqCompleteDate = dateutil.uGetDateFormat('yyyy-MM-DD', new Date(result.detail.requestCompleteDate));
                    if (result.detail.alarmAddTargetDisplayInfo) this.alarmTargetDisplayInfo = result.detail.alarmAddTargetDisplayInfo;
                    if (result.detail.alarmAddTarget) this.alarmTargetPersonCode = result.detail.alarmAddTarget;
                }

                this.isLoadedHelpdeskData = true
            })
            //EDITOR 는 데이터 조회
        } else {
            //WRITE 에 경우 기본 값
            this.helpdeskCaseData = {
                detail: {
                    boardId: this.currentBoard,
                    articleId: 0, // 글작성이후 response 에 articleid 넘어옴
                    categoryLv1Id: 0,
                    categoryLv2Id: 0,
                    serviceTypeId: 0,
                    requestDate: null,
                    dueByDate: null,
                    status: <number>HelpdeskStatus.Registration,
                    ticketNo: new Date().getTime().toString(),
                    helpdeskContent: '',
                    fileCode: getGUID(false),
                    serverity: this.selectedServerity, //2022-06-25 긴급도추가
                    requestCompleteDate: null,
                    alarmAddTarget: ''
                },
                managers: [],
            }

            //this.mFileCode = this.helpdeskCaseData.detail.fileCode

            this.isLoadedHelpdeskData = true
            // 완료요청일 변경
            this.setRequestCompleteDate();            
        }
    }

    onSelectServerity(value: HelpdeskServerityType) {
        this.selectedServerity = value
        // 긴급도 변경에 따른 완료요청일 변경
        this.setRequestCompleteDate();
    }

    // 완료요청일 기본값 설정
    setRequestCompleteDate(){
        var dt = new Date();
        var interval = 0;
        switch(this.selectedServerity)
        {
            case HelpdeskServerityType.Low:
                interval = 7;
                break;  
            case HelpdeskServerityType.Moderate:
                interval = 3;
                break;  
            case HelpdeskServerityType.High:
                interval = 1;
                break;  
        }

        dt.setDate(dt.getDate() + interval);
        this.reqCompleteDate = dateutil.uGetDateFormat('yyyy-MM-DD', dt);
    }

    mounted() {
        //팝업 창 하단에 알수없는 공백이 생김 임시 조치로 해당 코드 작성
        //디자인팀 요청해서 수정 필요 함
        // [송평우] 2022-07-25 사이냅 에디터에서 높이를 100% 설정하지 못하는 영향도가 발생하여 주석 처리
        // let el = window.document.querySelector('#common-wrapper') as HTMLElement
        // el.style.height = 'initial'
    }

    setCategoryValue(value: helpdesk.CategoryValue) {
        this.selectedCategoryValue = value

        this.helpdeskCaseData.detail.categoryLv1Id = value.lv1
        this.helpdeskCaseData.detail.categoryLv2Id = value.lv2
        this.helpdeskCaseData.detail.serviceTypeId = value.service
        this.mContent = value.formHtml
    }

    async validation() {
        //카테고리선택안된경우
        let msg = 'INVALID PARAMETER'
        if (
            this.selectedCategoryValue == null ||
            this.selectedCategoryValue.lv1 == 0
        ) {
            msg = this.$t('LR_카테고리를_선택하세요').toString()
            await (window as any).alertAsync(msg)
            return false
        }

        return true
    }

    //base override
    async onSubmitClick() {
        if (await this.validation() == false) {
            return
        }

        this.isLoading = true
        //임시저장 사용 안함
        var queryModel = this.getRequestModel(false)

        if (queryModel && await this.checkValidate(queryModel)) {
            var requestPromise: Promise<any> = Promise.resolve()

            if (this.writeMode == 0 || this.writeMode == 2) {
                requestPromise = this.createArticle(queryModel)
            } else {
                queryModel.articleId = this.currentArticle
                requestPromise = this.updateArticle(queryModel)
            }

            requestPromise.then(async (res) => {
                if (
                    (res.createdId && Number(res.createdId)) ||
                    (res.modifiedId && Number(res.modifiedId))
                ) {
                    //등록의경우 createdId , 수정의 경우 modifiedId

                    //생성된 아이디 기록 및 숫자면 성공 케이스로 봄
                    //성공이후 케이스 등록 진행하면 됨

                    this.setUpdatedHelpdeskData(
                        this.writeMode == WriteMode.Write
                            ? res.createdId
                            : res.modifiedId,
                        this.writeMode
                    )

                    // 요청일, 알림수신자
                    this.helpdeskCaseData.detail.requestCompleteDate = new Date(this.reqCompleteDate);
                    this.helpdeskCaseData.detail.alarmAddTarget = this.alarmTargetPersonCode;

                    api.helpdesk
                        .createHelpdeskCase(this.helpdeskCaseData)
                        .then((result) => {
                            ///deb

                            this.handleClose(true)
                        })
                } else {
                    //실패케이스
                    await (window as any).alertAsync('케이스 등록이 실패하였습니다.')
                }
            })
            // .catch(err => { this.isLoading = false; });
        } else {
            this.isLoading = false
        }
    }

    async initPermission() {
        let strMenuId = this.$route.query.MENUID

        let menuPermission = null

        if (strMenuId == 'NaN') {
            let boardId = this.$route.query.SYSID as string
            let findMenu = menuHelpder.findOneBySysId(parseInt(boardId), 'BB')

            if (findMenu) {
                menuPermission = permissionHelper.GetMenuPermission(
                    findMenu.menuId
                )
            } else {
                await (window as any).alertAsync('잘못된 접근입니다.')
                this.onCancelClick()
            }
        } else {
            //리스트에서 유입된 경우
            menuPermission = permissionHelper.GetMenuPermission(
                parseInt(strMenuId as string)
            )
        }

        //메뉴 관리자에 등록된 사용자( Helpdesk 관리자 )
        if (menuPermission.IsAdmin == true) {
            this.isHelpdeskAdmin = true
        }
    }

    setUpdatedHelpdeskData(articleId: number, mode: WriteMode) {
        if (mode == WriteMode.Write) {
            // 작성인경우 초기화된 기본 값
            this.helpdeskCaseData.detail.articleId = articleId
            this.helpdeskCaseData.subject = this.mTitle
            this.helpdeskCaseData.boardContent = this.mContent
            this.helpdeskCaseData.timezoneOffset =
                new Date().getTimezoneOffset()
            this.helpdeskCaseData.detail.serverity = this.selectedServerity
        } else {
           
            //수정의 경우 하위 HelpdeskComponent 에서 데이터 가져옴
            //Helpdesk 에서 직접 가져옴
            const helpdeskComponent = this.$refs
                .helpdeskComponent as HelpdeskComponentTs
            this.helpdeskCaseData = {
                detail: {
                    ...this.helpdeskCaseData.detail,
                    // boardId: this.currentBoard, //고정
                    // articleId: articleId,//고정
                    // ticketNo: this.helpdeskCaseData.detail.ticketNo,//고정
                    categoryLv1Id: (
                        this.selectedCategoryValue as helpdesk.CategoryValue
                    ).lv1,
                    categoryLv2Id: (
                        this.selectedCategoryValue as helpdesk.CategoryValue
                    ).lv2,
                    serviceTypeId: (
                        this.selectedCategoryValue as helpdesk.CategoryValue
                    ).service,
                    requestDate: new Date(
                        `${helpdeskComponent.requestDate} 00:00:00+00`
                    ),
                    dueByDate: new Date(
                        `${helpdeskComponent.dueByDate} 00:00:00+00`
                    ),
                    status: helpdeskComponent.selectedStatus,
                    helpdeskContent: helpdeskComponent.getEditorContent(),
                    fileCode: helpdeskComponent.mFileCode,
                    serverity: this.selectedServerity,
                },
                managers: [
                    {
                        //수정관계 없이 무조건 지우고 재등록
                        helpdeskId: this.helpdeskCaseData.detail.helpdeskId,
                        personCode: helpdeskComponent.selectedManagerPersonCode,
                    },
                ],
                menuId: this.getMenuIdBySysId(),
                subject: this.mTitle,
                boardContent: this.mContent,
                timezoneOffset: new Date().getTimezoneOffset(),
            }
        }
    }

    getMenuIdBySysId() {
        let menu = menuHelpder.findOneBySysId(
            parseInt(this.$route.query.SYSID as string),
            'BB'
        )

        if (menu) {
            return menu.menuId
        } else {
            return 0
        }
    }


    closeModal(){
       this.showHelpdeskNotice = false
    }


    popupOrg() {
        const options: TWishContainerOptions = {
            addableDept: false,
            single: true,
            containers: [
                { id: 0, name: this.$t("담당자").toString(), height: '1' }
            ]
        }
        const props: TWishItemPropsOptions = {
            props: ['id', 'name', 'personCode', 'deptName', 'deptCode'],
        }

        if (this.isTeams) {
            modalHelper.show({
                component: OrgModalView, force: true, value: {
                    options: options,
                    props: props,
                    event: EVENT_ORG_POPUP
                }
            });
        } else {
            openPopup(
                'helpdeskTarget',
                '/orgmap',
                { event: EVENT_ORG_POPUP, option: options, props },
                {
                    width: 1024,
                    height: 700,
                }
            )
        }
    }

    handlePopupResult() {
        const result = getDispatchData(EVENT_ORG_POPUP) || {}
        if (result[0].items.length > 0) {
            let user = result[0].items[0]
            this.alarmTargetDisplayInfo = `${user.name} (${user.deptName})`
            this.alarmTargetPersonCode = user.personCode
        }
    }
}
