import Vue from 'vue';
import { Component , Prop, PropSync } from 'vue-property-decorator';
//import * as types from '@/store/types'
import Datepicker from 'vuejs-datepicker'
import * as dateutil from '@appbase/util/date-helper';
import { ko } from "vuejs-datepicker/dist/locale";
import { getLangProperty } from '@appbase/util';
import { IEDate } from '@appbase/util/date-helper';

@Component({
    components:{
        Datepicker
    }
})
export default class DatetimeField extends Vue {
    @Prop ({ type: Object }) context!: board.ContentField;
    @PropSync('returnValue', { type: String }) dateModel!: string | null;
    @Prop({ type: Object }) initOptions!: any;

    localeKr: Object = ko;
    pickerValue: Date | null = null;
    colspan: number = 1;

    get displayName() {
        return getLangProperty(this.context, 'displayName');
    }

    created() {
        if(this.dateModel!= null && this.dateModel.length > 0){
            this.pickerValue = IEDate(this.dateModel);
        } else {
            this.pickerValue = new Date();     

            this.dateModel = this.pickerValue == null ? 
                null : dateutil.uGetDateFormat('yyyy-MM-DD', this.pickerValue); 
        }

        if (this.initOptions && this.initOptions.DatetimeField) {
            var options = this.initOptions.DatetimeField;
        }  
    }

    updated() {
        this.dateModel = this.pickerValue == null ? 
            null : dateutil.uGetDateFormat('yyyy-MM-DD', this.pickerValue);

        console.log('updated', this.dateModel);
    }

    dateFormatter(date: Date) {        
        return dateutil.uGetDateFormat('yyyy년 MM월 DD일', date);
    }
}