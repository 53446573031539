import Vue from 'vue'
import { Component, PropSync } from 'vue-property-decorator'

@Component({
    
})
export default class TagField  extends Vue {
    @PropSync('initialTags', { type: Array }) initialTagsSync!: string[];

    mTagText: string = "";

    created() {

    }

    onKeyDown(e: KeyboardEvent) {
        if(e.key === 'Backspace' || e.key === 'Delete') {
            if(this.mTagText.length == 0 && this.initialTagsSync.length > 0) {
                this.initialTagsSync.splice(this.initialTagsSync.length - 1, 1);
            }
        } 
        else if(e.key === 'Enter') {
            this.initialTagsSync.push(this.mTagText);
            this.mTagText = "";
        }
    }
}