import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { HelpdeskServerityType } from "@board/types/helpdesk"

@Component({
    components: {
       
    }
})
export default class HelpdeskServerity extends Vue {
    ServerityLevels : Array<helpdesk.HelpdeskServerity> = [
        // {value : HelpdeskServerityType.None, name : "None", description : "Not set"},
        {value : HelpdeskServerityType.Low, name : "Low", description : "Within a week"},
        {value : HelpdeskServerityType.Moderate, name : "Moderate", description : "Within 3 days"},
        {value : HelpdeskServerityType.High, name : "High", description : "Within 24 hours"},
        {value : HelpdeskServerityType.Critical, name : "Critical", description : "Within an hour"},
    ]

    // selectedSeverity : HelpdeskServerityType = HelpdeskServerityType.None
    @Prop({ type: Number, default: HelpdeskServerityType.Low}) value!: HelpdeskServerityType  //없는 경우도 있음 write 

    onSelect(value : HelpdeskServerityType){
       // this.value = value
        this.$emit('select', value)
    }

}
