import { render, staticRenderFns } from "./TextAreaField.vue?vue&type=template&id=75642bc2"
import script from "./TextAreaField.ts?vue&type=script&lang=ts&external"
export * from "./TextAreaField.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports