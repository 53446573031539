import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import api from '@board/api'
import HelpdeskCategoryDropdown from "./HelpdeskCategoryDropdown.vue"
import { HelpdeskCategoryType } from "@board/types/helpdesk"

@Component({
    components: {
        HelpdeskCategoryDropdown
    }
})
export default class HelpdeskCategories extends Vue {
    @Prop({ type: Object }) value?: helpdesk.CategoryValue //없는 경우도 있음 write 
    @Prop({ type: Boolean, default: true }) readOnly!: boolean

    selectedCategoryLv1: number | null = null
    selectedCategoryLv2: number | null = null
    selectedServiceType: number | null = null

    categories: Array<helpdesk.Category> = []
    categoryLv1: Array<helpdesk.Category> = []
    categoryLv2: Array<helpdesk.Category> = []
    servieTypes: Array<helpdesk.Category> = []
    formHtml: string = ''

    boardId : number = 0 //카테고리 조회시 현재 boardid 파람ㅇ로 전달 해당 카테고리만 조회


    //2022-08-09 카테고리 값이 내가 선택한 값이 아닌 첫번째 값으로 초기화 되는 증상이 있음
    //isInitJob : boolean = false // 초기 작업인 경우 이전 선택한 카테고리 값이 초기화 되버리는 증상이 있음


    created() {
         
        let queryStr = this.$route.query.SYSID
        if(queryStr){
            this.boardId = parseInt(queryStr as string)
        }
        
        this.loadCategories()
       

        //loadCategories() -> 에서 created(hook 함수)로 이동
        if (this.value) {
            //값이 있는 경우 초기 작업 flag 지정
            //카테고리 값이 실제 저장한 값이 아닌 첫번째 값으로 초기화됨
            //watch에서 아래 값들이 변경될 때 초기화 시킴
            //flag 기록하여 watch 회피함
           // this.isInitJob = true
            this.selectedCategoryLv1 = this.value.lv1
            this.selectedCategoryLv2 = this.value.lv2
            this.selectedServiceType = this.value.service
        } 

    }

    loadCategories() {
        api.helpdesk.getHelpdeskCategories(this.boardId).then(result => {

            if (result && result.length > 0) {
                this.categories = result

                this.initCategory()
               

                //value 가 있으면 기본값 set (EDIT 대응)

                // if (this.value) {
                //     //값이 있는 경우 초기 작업 flag 지정
                //     //카테고리 값이 실제 저장한 값이 아닌 첫번째 값으로 초기화됨
                //     //watch에서 아래 값들이 변경될 때 초기화 시킴
                //     //flag 기록하여 watch 회피함
                //     this.isInitJob = true
                //     this.selectedCategoryLv1 = this.value.lv1
                //     this.selectedCategoryLv2 = this.value.lv2
                //     this.selectedServiceType = this.value.service
                // } 

                
              
            }
        })
    }

    initCategory() {

        // 카테고리 Level1 초기화
        this.categoryLv1 = this.filterCategory('category', null, 1, true)

        //없으면 0으로 기본값
        if (this.categoryLv1.length > 0) {
            //쓰기시에 선택안된 상태로 
            //this.selectedCategoryLv1 = this.categoryLv1[0].categoryId
        }

        this.categoryLv2 = this.filterCategory('category', this.selectedCategoryLv1, 2)

        if (this.categoryLv2.length > 0) {
            //쓰기시에 선택안된 상태로 
            // this.selectedCategoryLv2 = this.categoryLv2[0].categoryId
        }

        //Service Type 은 카테고리1 에 따라 변경 됨
        this.servieTypes = this.filterCategory("service", this.selectedCategoryLv1)

        if (this.servieTypes.length > 0) {
            //쓰기시에 선택안된 상태로 
            // this.selectedServiceType = this.servieTypes[0].categoryId
        }

    }

    filterCategory(filterType: string, parentId?: number | null, categoryLevel?: number, isLv1?: boolean) {
        let resultArry: Array<helpdesk.Category> = []



        resultArry = this.categories.filter(item => {
            return item.categoryType == filterType
                && (categoryLevel ? item.categoryLevel == categoryLevel : true)
                && (parentId ? item.parentId == parentId : true)
        })

        //기본값인 선택하세요 카테고리 전부 추가
        if (isLv1 && isLv1 == true) {
            resultArry.unshift({
                categoryId: 0, //아이디가 0인경우는 없음 기본값 선택하세요
                categoryType: '',
                parentId: 0,
                categoryLevel: 0,
                categoryName: this.$t("LR_카테고리를_선택_하세요").toString(),
                isUse: true,
                formHtml: ''
            })
        }


        return resultArry
    }

    getCategoryNameById(id: number) {
        let findItem = this.categories.find(item => {
            return item.categoryId == id
        })

        if (findItem) {
            return findItem.categoryName
        } else { //없는 경우
            return "UNKNOWN"
        }
    }

    @Watch("selectedCategoryLv1")
    onChangeCategoryLv1(newVal: number) {

        // if(this.isInitJob==true){
        //     this.isInitJob = false
        //     return
        // }

        //선택하세요 가 선택된경우 l2 service 값 0ㅇ로 초기화
        if(newVal == 0){
            this.selectedCategoryLv2 = 0
            this.selectedServiceType = 0

            return
        }

        // category 1 lv 변경 시 category2, 서비스 타입 변경 됨

        this.categoryLv2 = this.filterCategory('category', newVal, 2)
        if (this.categoryLv2.length > 0) {
            this.selectedCategoryLv2 = this.categoryLv2[0].categoryId
        }

        this.servieTypes = this.filterCategory("service", newVal)
        if (this.servieTypes.length > 0) {
            this.selectedServiceType = this.servieTypes[0].categoryId
        }

        this.onChangeValue()
    }


    @Watch("selectedCategoryLv2")
    @Watch("selectedServiceType")
    onChangeValue() {
        this.$emit('select', {
            lv1: this.selectedCategoryLv1,
            lv2: this.selectedCategoryLv2,
            service: this.selectedServiceType,
            formHtml: this.formHtml
        } as helpdesk.CategoryValue)
    }
 

    updatedCategoryValue(type: HelpdeskCategoryType, value: number) {
       
        switch (type) {
            case HelpdeskCategoryType.Lv1:
                this.selectedCategoryLv1 = value
                break
            case HelpdeskCategoryType.Lv2:
                this.selectedCategoryLv2 = value
                var selCategory = this.categoryLv2.find(x => x.categoryId == value)
                if (selCategory){
                    this.formHtml = selCategory.formHtml;
                }
                break
            case HelpdeskCategoryType.Service:
                this.selectedServiceType = value
                break
        }
    }
}
