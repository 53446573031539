import Vue from 'vue';
import { Component , Prop , PropSync, Watch} from 'vue-property-decorator';
import { combindWithQuery, createEventBus, getLangProperty, invalidateUrl, removeEventBus} from '@appbase/util';
import { openPopup } from '@/modules/@appbase/util';
import GridView from '@appbase/components/GridView/GridView.vue'
import IFrameContainer from '../../common/IFrameContainer.vue';

export interface TWishContainerOptionItem {
    id: number
    name: string
    height: string
    items? : orgmap.TWishContainerItem[]
}

export interface TWishContainerOptions {
    addableDept: boolean,
    single: boolean,
    personal: boolean,
    public: boolean,
    containers: TWishContainerOptionItem[]
}

export interface TWishItemPropsOptions {
    props: string[]
}

const EVENT_ORG_POPUP = '$board/write/org/popup'

@Component({
    components: { GridView, IFrameContainer }
})

export default class MailSendField extends Vue {
    @Prop ({ type: Object }) context!: board.ContentField;
    @PropSync('returnValue', { type: String }) fieldValue!: string;

    receiversModel: gridView.ModelItem<board.AlarmReceiver>[] = [];
    receiversHeaders: gridView.Header[] = [];

    receivers: board.AlarmReceiver[] = [];
    selected: any[] = [];

    mShowOrgChart: boolean = false;
    orgChartSrc: string = invalidateUrl('/orgmap/popup?event=%24board%2Fwrite%2Forg%2Fpopup&option=%7B%22addableDept%22%3Atrue,%22single%22%3Afalse,%22personal%22%3Afalse,%22public%22%3Afalse,%22containers%22%3A%5B%7B%22id%22%3A0,%22name%22%3A%22%EB%B0%9B%EB%8A%94%EC%82%AC%EB%9E%8C%22,%22height%22%3A%221%22%7D%5D%7D&props=%7B%22props%22%3A%5B%22name%22,%22personCode%22,%22deptCode%22,%22deptName%22,%22email%22%5D%7D');

    created() {
        createEventBus(window, EVENT_ORG_POPUP, this.handlePopupResult);

        if(this.context.fieldPreset && this.context.fieldPreset != null) {
            var fieldParse = JSON.parse(this.context.fieldPreset); // 기본 수신자 지정

            if(Array.isArray(fieldParse)) {
                this.receivers = fieldParse;
            }
        }            

        if (this.fieldValue && this.fieldValue != null) {            
            var receiverArr =  JSON.parse(this.fieldValue);

            if(Array.isArray(receiverArr)) {
                receiverArr.forEach((receiver: board.AlarmReceiver) => {
                    if(!(this.receivers.findIndex(r => receiver.userEmail === r.userEmail) >= 0)) {
                        this.receivers.push(receiver)
                    }
                })
            }
        }

        this.receiversHeaders = [
            { displayName: this.$t('이름').toString(), rowKey: 'userName', align: 'left', slotName: 'userName', width: '30%' },
            { displayName: this.$t('이메일').toString(), rowKey: 'userEmail', align: 'left', slotName: 'userEmail', width: '*' },
        ]      
    }

    beforeDestroy() {
        removeEventBus(window, EVENT_ORG_POPUP, this.handlePopupResult);
    }

    get displayName() {
        return getLangProperty(this.context, 'displayName');
    }

    @Watch('receivers')
    onReceiversChanged(newVal: any[]) {
        this.receiversModel = newVal;
        this.fieldValue = JSON.stringify(newVal);
    }

    onOrgmapPopup() {
        const orgmapOption: TWishContainerOptions = {
            addableDept: true,
            single: false,
            personal: false,
            public: false,
            containers: [
               { id: 0, name: '받는사람', height: '1' }               
            ] 
        }

        const props: TWishItemPropsOptions = {
            props: ['name', 'personCode', 'deptCode', 'deptName', 'email'],
        }    

        if(this.isTeams) {
            var url = invalidateUrl('/orgmap/popup');
            var combineUrl = combindWithQuery(url, { event: EVENT_ORG_POPUP, option: orgmapOption, props });
            this.orgChartSrc = combineUrl;
            this.mShowOrgChart = true;
        } else {
            openPopup('mailSend', '/orgmap', 
            { event: EVENT_ORG_POPUP, option: orgmapOption, props }, 
            { width: 1024, height: 768 });
        }        
    }

    handlePopupResult(data: any) {
        this.mShowOrgChart = false;
        console.log('popupResult', data);
        if(data && data.length > 0) {
            var popupResult: any[] = data[0].items;
            popupResult.forEach(p => {
                if(!(this.receivers.findIndex(r => p.email === r.userEmail) >= 0)) {
                    this.receivers.push({
                        userName: p.name,
                        userCode: p.personCode,
                        deptCode: p.deptCode,
                        deptName: p.deptName,
                        userEmail: p.email,
                    })
                }
            })
        }
    }

    onDeleteItem() {
        this.selected.forEach(item => {
            var index = this.receivers.findIndex(r => r.userCode === item.userCode)
            this.receivers.splice(index, 1)
        })
    }

    onSelected(items: any[]) {
        this.selected = items;
    }
}