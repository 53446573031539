import Vue from 'vue';
import * as types from '@board/store/types'
import { Component } from 'vue-property-decorator';
import * as dateutil from '@appbase/util/date-helper'; 
import { mapActions } from 'vuex';

@Component({
    methods: {
        ...mapActions({
            getViewLogList: types.FETCH_VIEWLOGS
        })
    } 
})

export default class BoardViewLog extends Vue {
    getViewLogList!: (articleId: number) => Promise<article.ViewLogSet[]>;

    currentArticle: number = 0;
    viewLogs: article.ViewLogSet[] = [];

    beforeMount(){
        if(this.$route.query) {
            var query = this.$route.query;

            if(!(query.articleId))
                return;
            this.currentArticle = Number(query.articleId);
        }
        this.getViewLogList(this.currentArticle)
            .then(res => {
                this.viewLogs = res;
            })
    }

    getArticleFieldValue(viewLog: article.ViewLogSet){
        var date = new Date(viewLog.viewDate);
        return dateutil.uGetDateFormat_UTC('yyyy-MM-DD H:i', date);
    }
}