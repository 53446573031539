import Vue from 'vue'
import { Component,  Prop, Watch } from 'vue-property-decorator'
import {HelpdeskCategoryType} from "@board/types/helpdesk"
 
 

@Component
export default class HelpdeskCategoryDropdown extends Vue {
    @Prop({ type: Number }) value!: number
    @Prop({ type: Number  }) type!: HelpdeskCategoryType | number
    @Prop({ type: Array }) items!: Array<helpdesk.Category> //없는 경우도 있음 write 


    selectedValue : number = 0

    created() {
        if(this.value){
            this.selectedValue = this.value
        }
       
    } 

    @Watch("value")
    onChanveValue(){
        this.selectedValue = this.value
    }

    @Watch("selectedValue")
    onChangeSelectedValue(){
        this.$emit('select',this.type,this.selectedValue)
    }

}
