import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { getLangProperty } from '@appbase/util';
import CheckBox from '@board/components/common/CheckBox.vue'

@Component({
    components: {
        CheckBox
    }
})
export default class CheckField extends Vue {
    @Prop ({ type: Object }) context!: board.ContentField;
    @PropSync('returnValue', { type: Boolean }) checkModel!: boolean;
    @Prop({ type: Object }) initOptions!: any;

    get displayName() {
        return getLangProperty(this.context, 'displayName');
    }

    created(){
        if (this.initOptions && this.initOptions.CheckField) {
            var options = this.initOptions.CheckField;
        }
    }    
}