import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import { EumEditor } from '@appbase/components/editor'
import { FlowFileUploader } from '@appbase/components/common'
import { invalidateUrl } from '@appbase/util/invalidate-url'
import { FlowFileMeta } from '@appbase/components/common/FlowFileUploader/flow-file-meta'
import { readablizeBytes } from '@appbase/util/size-helper'
import * as types from '@board/store/types'
import * as dateutil from '@appbase/util/date-helper'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import { openPopup } from '@appbase/util';
import modalHelper from '@appbase/util/modal-helper';
import OrgModalView from '@orgmap/views/OrgmapModalView.vue'
import { createEventBus, getDispatchData, removeEventBus } from '@appbase/util/eventBus'
import { HelpdeskStatus } from "@board/types/helpdesk"
import AttachFileField from '@board/components/board/readComponents/AttachFileField.vue';
import flowHelper from '@appbase/components/common/FlowFileUploader/flow-helper'

const enum WriteMode {
    Write = 0,
    Edit = 1,
    Reply = 2,
}

export interface TWishContainerOptionItem {
    id: number
    name: string
    height: string
    items?: orgmap.TWishContainerItem[]
}
export interface TWishContainerOptions {
    addableDept: boolean,
    single: boolean,
    containers: TWishContainerOptionItem[]

}

export interface TWishItemPropsOptions {
    props: string[]
}

export interface HelpdeskStatusObject {
    value: HelpdeskStatus
    text: string
}

const EVENT_ORG_POPUP = '$board/helpdesk/org/popup'

@Component({
    components: {
        FlowFileUploader,
        EumEditor,
        DatePicker,
        AttachFileField
    },
    methods: mapActions({
        readArticle: types.READ_ARTICLE,
        fetchFile: types.FETCH_FILE,
        createFile: types.CREATE_FILE,

    }),
})
export default class HelpdeskComponent extends Vue {
    @Prop({ type: Object }) value!: helpdesk.RequestHelpdeskCase //없는 경우도 있음 write 
    @Prop({ type: Boolean, default: false }) isReadOnly!: boolean //없는 경우도 있음 write 

    readArticle!: (articleSet: article.ArticleFetchSet) => Promise<article.ArticleDetail>
    fetchFile!: (code: string) => Promise<article.FileStore[]>
    createFile!: (set: article.FileCreateSet) => Promise<number>

    isEdit: boolean = false

    articleId: number = 0
    boardId: number = 0
    writeMode: WriteMode = WriteMode.Write

    selectedStatus: HelpdeskStatus = HelpdeskStatus.Registration
    helpdeskStatus: Array<HelpdeskStatusObject> = []
    selectedManagerPersonCode: string = ""
    selectedManagerDisplayinfo: string = ""
    requestDate: string = ""
    dueByDate: string = ""

    helpDeskContent: string = ""



    //editor
    tinyOptions: any = {
        content_style: `
                    p { font-family: dotum; }
                    body {font-family: dotum; font-size: 10pt;}
                    body#tinymce p {
                        font-family: dotum; 
                        margin-top: 0.3em;
                        margin-bottom: 0.3em;
                    }`,
        height: 500,
        inline: false,
        menubar: true,
        language: 'ko_KR',
    }

    //fileupload
    files: any[] = []
    uploadUrl: string = ""
    deleteUrl: string = ""
    isFileUploading: boolean = false
    mFileCode: string = ''
    sizeLimit: number | null = null
    mFileSizeDesc: string = ''
    uploaderConfig: FlowFileMeta = {
        code: '',
        codeValues: [],
        type: 'file',
    }


    created() {
        //querystring 확인 후 articleId 에 값이  있으면 edit 로 봄
        if (this.$route.query.ARTICLEID && this.isReadOnly==false) {
            this.isEdit = true
        }


        createEventBus(window, EVENT_ORG_POPUP, this.handlePopupResult)

        this.uploadUrl = invalidateUrl('/cm/file/store/flow')
        this.deleteUrl = invalidateUrl('/cm/file/store/deleteFile')

        this.initHelpdeskStatus()
        this.initHelpdeskData()
    }

    beforeDestroy() {
        removeEventBus(window, EVENT_ORG_POPUP, this.handlePopupResult)
    }


    initHelpdeskData() {
        let baseDate = new Date()
 
        //날짜 기본 값d
        if (this.value.detail.requestDate) {

            this.requestDate = dateutil.uGetDateFormat("yyyy-MM-DD", new Date(this.value.detail.requestDate))
        } else {
            //requestDate 기 null 인 경우 (조회된 데이터가 X , 등록 상태)

            if (this.isEdit == true) {
                this.requestDate =  dateutil.uGetDateFormat("yyyy-MM-DD", baseDate)
            } else {
                this.requestDate = ""
            }
        }
        if (this.value.detail.dueByDate) {
            this.dueByDate = dateutil.uGetDateFormat("yyyy-MM-DD", new Date(this.value.detail.dueByDate))
           
        } else {
            if (this.isEdit == true) {
                baseDate.setDate(baseDate.getDate() + 3) //처리 일 접수일로부터 + 3
                this.dueByDate = dateutil.uGetDateFormat("yyyy-MM-DD",baseDate)

            } else {
                this.dueByDate = ""
            }
        }
        //매니저정보
        if(this.value.managers.length > 0){
            this.selectedManagerDisplayinfo = this.value.managers[0].displayName || ""
            this.selectedManagerPersonCode = this.value.managers[0].personCode
        }
     

        this.helpDeskContent = this.value.detail.helpdeskContent
        this.mFileCode = this.value.detail.fileCode
        this.uploaderConfig.code = this.mFileCode
        this.uploaderConfig.codeValues = ["board_helpdesk",this.boardId.toString()]

        //첨부파일 관련
        this.fetchFile(this.value.detail.fileCode.toString()).then((res) => {
            
            this.files = res.map(
                (m) => flowHelper.convertToFlowFile({
                    ...m,
                    uniqueIdentifier: m.serverPath,
                    type: 'Database'
                })
            )
            this.updateSizeDesc()
        })
    }

    initHelpdeskStatus() {
        // 등록완료/결재필요/결재완료/접수/담당자배정/처리완료
        this.helpdeskStatus = [
            {
                value: HelpdeskStatus.Registration,
                text: this.$t("등록").toString()
            },
            {
                value: HelpdeskStatus.Receive,
                text: this.$t("접수").toString()
            },
            {
                value: HelpdeskStatus.Assign,
                text: this.$t("담당자배정").toString()
            },
            {
                value: HelpdeskStatus.Completed,
                text: this.$t("처리완료").toString()
            },
            {
                value: HelpdeskStatus.Reject,
                text: this.$t("LR_거절").toString()
            },
            {
                value: HelpdeskStatus.Hold,
                text: this.$t("LR_보류").toString()
            },
            

        ]
 
        this.selectedStatus = this.value.detail.status as number
       



    }


    fileUploadStatusChaged(data: any) {
        if (data === 'uploading') {
            this.isFileUploading = true
        } else {
            this.isFileUploading = false
        }
    }

    fileItemsChanged(files: any[]) {
         
        if (files == null || files.length == 0) {
            this.updateSizeDesc()
        } else {
            if (this.writeMode == WriteMode.Edit && this.mFileCode != null) {
                this.createFile({
                    articleId: this.articleId,
                    boardId: this.boardId,
                    fileCode: this.mFileCode,
                    files: files.map(
                        (f) =>
                            <article.FileItem>{
                                name: f.name,
                                size: f.size,
                            }
                    ),
                })
            }

            this.updateSizeDesc()
        }
    }

    updateSizeDesc() {
        if (this.files == null || this.files.length == 0) {
            if (this.sizeLimit != null) {
                this.mFileSizeDesc = `( 0 / ${readablizeBytes(this.sizeLimit)})`
            } else {
                this.mFileSizeDesc = ''
            }
        } else {
            var sizeTotal = this.files.reduce((p, c, i) => p + c.size, 0)

            if (this.sizeLimit == null) {
                this.mFileSizeDesc = `( ${readablizeBytes(sizeTotal)})`
            } else {
                this.mFileSizeDesc = `( ${readablizeBytes(sizeTotal)} / ${readablizeBytes(this.sizeLimit)})`
            }
        }
    }



    ///조직도 팝업

    popupOrg() {

        const options: TWishContainerOptions = {
            addableDept: false,
            single: true,
            containers: [
                { id: 0, name: this.$t("담당자").toString(), height: '1' }
            ]


        }
        const props: TWishItemPropsOptions = {
            props: ['id', 'name', 'personCode', 'deptName', 'deptCode'],
        }

        if (this.isTeams) {

            modalHelper.show({
                component: OrgModalView, force: true, value: {
                    options: options,
                    props: props,
                    event: EVENT_ORG_POPUP
                }
            });
        } else {

            openPopup(
                'helpdeskTarget',
                '/orgmap',
                { event: EVENT_ORG_POPUP, option: options, props },
                {
                    width: 1024,
                    height: 700,
                }
            )
        }
    }

    handlePopupResult() {

        const result = getDispatchData(EVENT_ORG_POPUP) || {}

        //여기서 바로 올려야되네

        //name
        //deptName
        //personCode
        if (result[0].items.length > 0) {

            let user = result[0].items[0]
            // "Kihun Kim 김기훈 (IT [한국법인])"
            this.selectedManagerDisplayinfo = `${user.name} (${user.deptName})`
            this.selectedManagerPersonCode = user.personCode


        }

    }


    getStatusText(status: number) {



        var result = this.helpdeskStatus.find(item => {
            return item.value == status
        })

        if (result) {
            return result.text
        } else {
            return ""
        }

    }


    getManagerDisplayName() {
        if (this.value.managers.length > 0) {
            if (this.value.managers[0].displayName) {
                return this.value.managers[0].displayName
            } else {
                return ""
            }

        } else {
            return ""
        }
    }



    getEditorContent(){
        const editor: editor.IEditorProxy = this.$refs.editor2 as any
        var d = editor.getEditor().getBodyValue();
        return d
    }
 
    getDisplayRequestCompleteDate(){
        if (this.value.detail.requestCompleteDate){
            return dateutil.uGetDateFormat('yyyy-MM-DD', new Date(this.value.detail.requestCompleteDate));
        }
        return "";
    }

    // @Watch("selectedStatus")
    // @Watch("selectedStatus")
    // @Watch("selectedStatus")

    // onChangValue(){
    //     console.log("================= changed valued =================")
    // }

}
