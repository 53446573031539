import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { getLangProperty } from '@appbase/util';
//import * as types from '@/store/types'

@Component({
})

export default class SelectField extends Vue {
    @Prop({ type: Object }) context!: board.ContentField;  
    @PropSync('returnValue', { type: String }) selectModel!: string;
    @Prop({ type: Object }) initOptions!: any;

    options: field.FieldOptions[] = [];
    currentSelect: number = 0;
    colspan: number = 1;

    get displayName() {
        return getLangProperty(this.context, 'displayName');
    }

    created() {
        var jsonObject = JSON.parse(this.context.fieldPreset);
        this.options = jsonObject;
        
        if(this.selectModel != null) {
            var selected = JSON.parse(this.selectModel);
            this.currentSelect = selected.ID;            
        } else {
            this.currentSelect = this.options[0].ID;
            this.selectModel = JSON.stringify({ ID: this.options[0].ID, TEXT: this.options[0].TEXT });
        }

        if (this.initOptions && this.initOptions.SelectField) {
            var options = this.initOptions.SelectField;
        }       
    }

    getSelectedVal() {
        var selectField = (this.$refs.selectedField as HTMLSelectElement)
        var selectIdx = selectField.options.selectedIndex;
        var selectedval = selectField.options[selectIdx].text;
        var selectedId = selectField.options[selectIdx].value;

        var jsonString = JSON.stringify({ ID: selectedId, TEXT: selectedval });
        this.selectModel = jsonString;

        this.$emit('submit', jsonString);
    }
}