import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { getLangProperty } from '@appbase/util';
//import * as types from '@/store/types'

@Component({
})

export default class TextField extends Vue {
    @Prop ({ type: Object }) context!: board.ContentField;
    @PropSync('returnValue', { type: String }) textModel!: string;
    @Prop({ type: Object }) initOptions!: any;

    created() {
        if (this.initOptions && this.initOptions.TextField) {
            var options = this.initOptions.TextField;
        }
    }

    get displayName() {
        return getLangProperty(this.context, 'displayName');
    }
}