<template>
  <!-- apiKey="n22ppxqgsekqsnnft30wpegss1n9licatuvv2pxfso1r0arz" -->
  <editor
    :initialValue="initialValue"
    :init="editorOptions"
    :disabled="disabled"
    :outputFormat="outputFormat"
    :value="value"
    @input="(value) => $emit('input', value)"
  >
  </editor>
</template>
<script>
/**
 * If you need detailed documentation on TinyMCE, see: TinyMCE Documentation.
 *  => https://www.tiny.cloud/docs/
 * For the TinyMCE Vue Quick Start, see: TinyMCE Documentation - Vue Integration.
 *  => https://www.tiny.cloud/docs/integrations/vue/#tinymcevuejsintegrationquickstartguide
 * For the TinyMCE Vue Technical Reference, see: TinyMCE Documentation - TinyMCE Vue Technical Reference.
 *  => https://www.tiny.cloud/docs/integrations/vue/#tinymcevuejstechnicalreference
 * For our quick demos, check out the TinyMCE Vue Storybook.
 *  => https://tinymce.github.io/tinymce-vue/?path=/story/tinymce-vue--iframe-editor
 */
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'TinyViewer',

  components: { Editor },

  props: {
    // 바인딩 데이터
    value: { type: String, default: '' },
    // 초기화 옵션
    init: { type: Object },
    // 초기화 시 표시할 기본 컨텐츠
    initialValue: { type: String, default: '' },
    // 비활성 여부
    disabled: { type: Boolean, default: false },
    // v-model 아웃풋 포멧
    outputFormat: { type: String, default: 'html' },
  },

  data() {
    return {
      menuBarDisplay: false,
      language: 'ko_KR',
      initialOptions: {
        content_style: `
p { font-family: arial; }
body {font-family: arial; font-size: 11pt; line-height: 1.2;}
body#tinymce p {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
}`,
        plugins: [
          'advlist autoresize autolink lists link image charmap print preview anchor textcolor',
          'searchreplace visualblocks advcode fullscreen',
          'insertdatetime media table contextmenu powerpaste mediaembed',
          'formatpainter permanentpen pageembed checklist casechange',
          //wordcount 모듈 삭제
        ],
        font_formats:
          '맑은 고딕=Malgun Gothic;' +
          '굴림=gulim;' +
          '굴림체=GulimChe;' +
          '궁서=Gungsuh;' +
          '궁서체=GungsuhChe;' +
          '돋움=dotum;' +
          '돋움체=DotumChe;' +
          '바탕=Batang;' +
          '바탕체=BatangChe;' +
          '새굴림=ngulim;' +
          '함초롬돋움=HCR Dotum;' +
          '함초롬바탕=HCR Batang;' +
          'Arial=arial,helvetica,sans-serif;' +
          'Arial Black=arial black,avant garde;' +
          'Comic Sans MS=comic sans ms, sans-serif;' +
          'Courier New=courier new, courier;' +
          'Times New Roman=times new roman,times;',
        fontsize_formats:
          '7pt 8pt 9pt 10pt 11pt 12pt 14pt 15pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 36pt 48pt 72pt',
        inline_styles: true,
        menubar: false,
        toolbar: false,
        resize: false,
        statusbar: false,
        autoresize_on_init: true,
        setup: function(editor) {
          editor.settings.readonly = true
          editor.on('init', function(e) {
            var divElem = editor.editorContainer
            divElem.classList.add('tox-mce-noborder')
          })
        },
      },

      editorOptions: {},
    }
  },

  created() {
    if (this.init) {
      this.editorOptions = Object.assign(this.initialOptions, this.init)
    } else {
      this.editorOptions = this.initialOptions
    }
  },

  methods: {},
}
</script>
<style>
.tox-mce-noborder {
  border: 0px !important;
}
</style>
