import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator'
import boardListBase from "@board/components/board/BoardList"
import { open, OpenType } from '@board/open-handler';
import api from '@board/api'
import * as dateHelpder from "@appbase/util/date-helper"
import { mapMutations } from 'vuex';
import * as types from "@board/store/types"

@Component({
    methods: {
        ...mapMutations({
            setHelpdeskCategories : types.Z_HELPDESK_CATEGORIES
        })
    }
})
export default class HelpdeskList extends boardListBase {

   

    categories: Array<helpdesk.Category> = []
    boardId : number = 0 //카테고리 조회시 현재 boardid 파람ㅇ로 전달 해당 카테고리만 조회
    setHelpdeskCategories! : (payload :Array<helpdesk.Category> ) => void

    created() {
        let queryStr = this.$route.query.SYSID
        if(queryStr){
            this.boardId = parseInt(queryStr as string)
        }
        
        this.loadCategories()

       
    }

    loadCategories() {
        api.helpdesk.getHelpdeskCategories(this.boardId).then(result => {

            if (result && result.length > 0) {
                this.categories = result
                this.setHelpdeskCategories(result)
            }
        })
    }

    findFieldByName(name: string) {
        return this.listFields.find(item => {
            return item.fieldValueName == name
        })
    }

    convertDatetime(value: string){
        if(value.startsWith("0001-01-01")) return
        return dateHelpder.uGetDateFormat("yyyy-MM-DD", new Date(value))
    }

    convertHelpdeskCategory(id: number) {
        return this.categories.find(item => {
            return item.categoryId == id
        })?.categoryName
    }


    async goDetailPage(article: article.ArticleDetail) {
        
        let openType = this.isTeams ? OpenType.HelpdeskModalRead : OpenType.HelpdeskPopupRead
        
        //2022-08-22 답글인경우
        //일반 게시판 read pop 

        if(article.parentArticle != null){
            openType = this.isTeams ? OpenType.ModalRead : OpenType.PopupRead
        }

        open(openType,
            {
                boardId: this.boardConfig.boardId,
                articleId: article.articleId,
                baseUrl: this.popupBaseUrl,
                menuId : parseInt(this.$route.query.MENUID as string)
            });

    }

    getServerityCss(displayname: string) {
         
        switch (displayname.toLowerCase()) {
            case "low":
                return "badge-gray"
            case "moderate":
                return "badge-blue"
            case "high":
                return "badge-yellow"
            case "critical":
                return "badge-red"
            default: //기본값 카테고리와 동일 색상 , None 등 추후 생성될 수도 있는 
                return "badge-green"
        }
    }


    //2022-08-02 항목변경 시 목록 초기화
    @Watch("viewCount")
    onChangedViewCount(newVal : number){
        
        // var searchSet = <article.SearchSet>{
        //     userCode: this.user.id,
        //     boardId: this.pageSet.boardId,
        //     page: this.pageSet.currentPage,
        //     itemsPerPage: newVal,
        //     searchField: this.pageSet.searchField,
        //     searchFieldValue: this.pageSet.searchText,
        //     prefaceId1: this.pageSet.preface1,
        //     prefaceId2: this.pageSet.preface2,
        //     sortField: this.currentField,
        //     sortOrder: this.sortOrder,
        //     includeContent: false,
        //     useNotice: this.boardConfig.usePrimaryNotice,
        //     isHelpdesk : this.boardConfig.zIsHelpDesk ? true : false //2022-06-07 헬프데스크 병합 기준 파람 추가
        // }

    //     boardId: number;
    //     currentPage: number;
    //     searchField: number | null;
    //     searchText: string | null;
    //     useNotice: boolean;
    //     preface1: string | null;
    //     preface2: string | null;
    //     helpdeskStatus? : number
    //     helpdeskCategory? : number 
    // }
        this.fetchPageSet({
            ...this.pageSet,
            currentPage : 1,
        })
 
        //this.debounceFetchArticle(searchSet,newVal.currentPage, this)

        // this.fetchArticle(searchSet).then(res => {
             
        //     this.searchItems = res.totalCount;
        //     this.$emit('articlesChanged', this.itemsPerPage, res.totalCount, this.pageSet.currentPage, res.data.length);
        // })
    }

}