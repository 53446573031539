import Vue from 'vue';
import { Component, PropSync, Prop } from 'vue-property-decorator';
import { EumEditor } from '@appbase/components/editor'
import { getLangProperty } from '@appbase/util';
@Component({
    components:{
        EumEditor
    }
})
export default class TextAreaField extends Vue {
    @Prop({ type: Object }) context!: board.ContentField;  
    @PropSync('returnValue', { type: String }) textModel!: string;
    @Prop({ type: Object }) initOptions!: any;

    colspan: number = 1;

    tinyopts: any = {
        content_style: `
                    p { font-family: dotum; }
                    body {font-family: dotum; font-size: 10pt;}
                    body#tinymce p {
                        font-family: dotum; 
                        margin-top: 0.3em;
                        margin-bottom: 0.3em;
                    }`,
        height: 500,
        inline: false,
        menubar: true,      
        language: "ko_KR"
    };  
    dext5opts: editor.dext5.DEXT5Config = {
        EditorBorder: '0',
    } 
    
    get displayName() {
        return getLangProperty(this.context, 'displayName');
    }


    created() {        
        if (this.initOptions && this.initOptions.TextAreaField) {            
            var options = this.initOptions.TextAreaField

            if (options.tinyOptions) {
                this.tinyopts = options.tinyOptions;
            }

            if (options.colspan) {
                this.colspan = options.colspan;
            }
        }

        this.tinyopts.auto_focus = true;        
    }
}