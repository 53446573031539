import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import { createEventBus, getGUID, getLangProperty, openPopup, removeEventBus } from '@appbase/util';
import { TWishContainerOptions, TWishItemPropsOptions } from '@gnb/components/Common/RecipientField';

const EVENT_ORG_POPUP = '$board/selectuser/org/popup'

@Component({

})
export default class SelectUserField  extends Vue {
    @Prop ({ type: Object }) context!: board.ContentField;
    @PropSync('returnValue', { type: String }) userModel!: string | null;
    @Prop({ type: Object }) initOptions!: any;

    componentGuid: string = getGUID(false);
    displayUserText: string = "";

    get displayName() {
        return getLangProperty(this.context, 'displayName');
    }

    created() {
        createEventBus(window, `${EVENT_ORG_POPUP}/${this.componentGuid}`, this.handleOrgchartCallback);

        if(this.userModel != null && this.userModel.length > 0) {
            var initObject = JSON.parse(this.userModel);
            this.displayUserText = `${initObject.name} ${initObject.title}`;
        }
    }

    beforeDestroy() {
        removeEventBus(window, `${EVENT_ORG_POPUP}/${this.componentGuid}`, this.handleOrgchartCallback);
    }

    onOrgchartClick() {
        const orgmapOption: TWishContainerOptions = {
            addableDept: false,
            single: true,
            containers: [
                { id: 0, name: this.$t('사용자').toString(), height: '1' } 
            ]
        };

        const props: TWishItemPropsOptions = {
            props: ['id', 'name', 'personCode', 'deptName', 'deptCode', 'title'],
        };

        openPopup('BoardSelectUser', '/orgmap', 
            { event: `${EVENT_ORG_POPUP}/${this.componentGuid}`, option: orgmapOption, props },
            { width: 1024, height: 768 });
    }

    onRemoveClick() {
        this.displayUserText = "";
        this.userModel = null;
    }

    handleOrgchartCallback(data: any) {
        if(data && data.length > 0) {
            var popupResult: any[] = data[0].items;
            
            if(popupResult != null && popupResult.length > 0) {
                this.displayUserText = `${popupResult[0].name} ${popupResult[0].title}`
                this.userModel = JSON.stringify(popupResult[0]);
            }            
        }
    }
}