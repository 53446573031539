<template>
  <!-- apiKey="n22ppxqgsekqsnnft30wpegss1n9licatuvv2pxfso1r0arz" -->
  <editor
    :initialValue="mInitialValue"
    :init="editorOptions"
    :disabled="disabled"
    :outputFormat="outputFormat"
    @onInit="onInit"
  >
  </editor>
</template>
<script>
/**
 * If you need detailed documentation on TinyMCE, see: TinyMCE Documentation.
 *  => https://www.tiny.cloud/docs/
 * For the TinyMCE Vue Quick Start, see: TinyMCE Documentation - Vue Integration.
 *  => https://www.tiny.cloud/docs/integrations/vue/#tinymcevuejsintegrationquickstartguide
 * For the TinyMCE Vue Technical Reference, see: TinyMCE Documentation - TinyMCE Vue Technical Reference.
 *  => https://www.tiny.cloud/docs/integrations/vue/#tinymcevuejstechnicalreference
 * For our quick demos, check out the TinyMCE Vue Storybook.
 *  => https://tinymce.github.io/tinymce-vue/?path=/story/tinymce-vue--iframe-editor
 */
import Editor from '@tinymce/tinymce-vue'

const pickerCallback = function(cb, value, meta) {
  console.log('picker callback', value, meta)
  var input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'image/*')
  input.onchange = function() {
    var file = this.files[0]

    var reader = new FileReader()
    reader.onload = function() {
      var id = 'blobid' + new Date().getTime()
      var blobCache = tinymce.activeEditor.editorUpload.blobCache
      var base64 = reader.result.split(',')[1]
      var blobInfo = blobCache.create(id, file, base64)
      blobCache.add(blobInfo)

      cb(blobInfo.blobUri(), { title: file.name })
    }

    reader.readAsDataURL(file)
    document.removeChild(input)
  }

  document.body.appendChild(input)
  input.click()
}

const checkInTableSelect = function(editor) {
  var node = editor.selection.getNode()
  if (node) {
    if (
      node.nodeName.toLowerCase() === 'table' ||
      node.nodeName.toLowerCase() === 'tr' ||
      node.nodeName.toLowerCase() === 'td'
    ) {
      return true
    }
  }

  return false
}

const setContextMenu = function(editor, lang) {
  editor.ui.registry.addMenuItem('cut', {
    icon: 'cut',
    text: lang === 'ko_KR' ? '잘라내기' : 'Cut',
    shortcut: 'Ctrl+X',
    onAction: function() {
      editor.execCommand('Cut')
    },
    onSetup: function(api) {
      var content = editor.selection.getContent()

      api.setDisabled(true)

      if (checkInTableSelect(editor)) {
        api.setDisabled(false)
      }

      if (content && content != null) {
        api.setDisabled(false)
      }
    },
  })

  editor.ui.registry.addMenuItem('copy', {
    icon: 'copy',
    text: lang === 'ko_KR' ? '복사' : 'Copy',
    shortcut: 'Ctrl+C',
    onAction: function() {
      editor.execCommand('Copy')
    },
    onSetup: function(api) {
      var content = editor.selection.getContent()

      api.setDisabled(true)

      if (checkInTableSelect(editor)) {
        api.setDisabled(false)
      }

      if (content && content != null) {
        api.setDisabled(false)
      }
    },
  })

  editor.ui.registry.addMenuItem('paste', {
    icon: 'paste',
    text: lang === 'ko_KR' ? '붙여넣기' : 'Paste',
    shortcut: 'Ctrl+V',
    onAction: function() {
      editor.execCommand('Paste')
    },
    onSetup: function(api) {
      var clipboardData = window.clipboardData

      if (clipboardData && clipboardData.getData) {
        api.setDisabled(false)
      } else {
        api.setDisabled(true)
      }
    },
  })

  editor.ui.registry.addMenuItem('delete', {
    icon: 'delete',
    text: lang === 'ko_KR' ? '삭제' : 'Delete',
    onAction: function() {
      editor.execCommand('Delete')
    },
    onSetup: function(api) {
      var content = editor.selection.getContent()

      api.setDisabled(true)

      if (checkInTableSelect(editor)) {
        api.setDisabled(false)
      }

      if (content && content != null) {
        api.setDisabled(false)
      }
    },
  })
}

export default {
  name: 'TinyEditorNoModel',

  components: { Editor },

  props: {
    // 바인딩 데이터
    value: { type: String, default: '' },
    // 초기화 옵션
    init: { type: Object },
    // 초기화 시 표시할 기본 컨텐츠
    initialValue: { type: String, default: '' },
    // 비활성 여부
    disabled: { type: Boolean, default: false },
    // v-model 아웃풋 포멧
    outputFormat: { type: String, default: 'html' },
  },

  data() {
    return {
      content: '',
      mInitialValue: '',
      menuBarDisplay: false,
      language: 'ko_KR',
      initialOptions: {
        content_style: `
p { font-family: arial; }
body {font-family: arial; font-size: 11pt; line-height: 1.2;}
body#tinymce p {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
}`,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor textcolor',
          'searchreplace visualblocks advcode fullscreen charmap',
          'insertdatetime media table contextmenu mediaembed powerpaste', // powerpaste
          'formatpainter permanentpen pageembed checklist casechange',
          //wordcount 모듈 삭제
        ],
        font_formats:
          '맑은 고딕=Malgun Gothic;' +
          '굴림=gulim;' +
          '굴림체=GulimChe;' +
          '궁서=Gungsuh;' +
          '궁서체=GungsuhChe;' +
          '돋움=dotum;' +
          '돋움체=DotumChe;' +
          '바탕=Batang;' +
          '바탕체=BatangChe;' +
          '새굴림=ngulim;' +
          '함초롬돋움=HCR Dotum;' +
          '함초롬바탕=HCR Batang;' +
          'Arial=arial,helvetica,sans-serif;' +
          'Arial Black=arial black,avant garde;' +
          'Comic Sans MS=comic sans ms, sans-serif;' +
          'Courier New=courier new, courier;' +
          'Times New Roman=times new roman,times;',
        toolbar1:
          'undo redo | fontselect fontsizeselect forecolor backcolor bold italic underline ' +
          '| alignleft aligncenter alignright alignjustify ',
        toolbar2: 'table bullist numlist outdent indent | link image charmap',
        fontsize_formats:
          '7pt 8pt 9pt 10pt 11pt 12pt 14pt 15pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 36pt 48pt 72pt',
        inline_styles: true,
        automatic_uploads: true,
        file_picker_types: 'image',
        file_picker_callback: pickerCallback,
        relative_urls: false,
        contextmenu: 'link | cut copy paste delete | table',
        //convert_urls: false,
        //contextmenu: 'cut copy paste', // 브라우저가 클립보드 접근을 지원하지 않습니다. Ctrl+X/C/V 단축키를 이용하십시오. <== contextmenu_never_use_native 추가 필요
        //contextmenu_never_use_native: true,
        powerpaste_html_import: 'merge',
        powerpaste_word_import: 'merge',
        powerpaste_keep_unsupported_src: true,
        powerpaste_allow_local_images: true,
      },

      editorOptions: {},
      updateCount: 0,
    }
  },

  watch: {
    value(newval) {
      console.log('tiny-editor-no-model', 'updateCount', ++this.updateCount)
      this.setContent(newval)
    },
  },

  created() {
    //const _this = this;
    //if (this.init) {
    //    this.editorOptions = Object.assign(this.initialOptions, this.init);
    //} else {
    //    this.editorOptions = this.initialOptions;
    //}
    const _this = this
    if (this.init) {
      var userSetup = this.init.setup
      this.editorOptions = Object.assign(this.initialOptions, this.init)
      this.editorOptions.setup = (ed) => {
        ed.on('init', function(e) {
          setContextMenu(ed, _this.editorOptions.language)
        })
        ed.on('blur', function(e) {
          _this.emitInput(e, ed)
        })
        // 사용자 지정 setup 핸들러 호출
        if (userSetup) {
          userSetup(ed)
        }
      }
    } else {
      this.editorOptions = this.initialOptions
    }

    if (this.initialValue) {
      this.mInitialValue = this.initialValue
    } else {
      this.mInitialValue = this.value
    }
  },

  methods: {
    onInit() {
      if (this.init.menubar) {
        var menuBar = document.getElementsByClassName('tox-menubar')[0]
        menuBar.style.display = 'none'
      }
    },

    //onKeyDown(e: KeyboardEvent) {
    //    if (e.keyCode == 18) {
    //        if (this.init.menubar) {
    //            e.preventDefault();
    //            var menuBar = document.getElementsByClassName('tox-menubar')[0] as HTMLElement;

    //            if (this.menuBarDisplay) {
    //                menuBar.style.display = "none";
    //                this.menuBarDisplay = false;
    //            } else {
    //                menuBar.style.display = "";
    //                this.menuBarDisplay = true;
    //            }
    //        }
    //    }
    //},

    emitInput(e, editor) {
      var content = editor.getContent()
      this.$emit('input', content)
    },

    getContent() {
      return tinymce.activeEditor.getContent()
    },

    setContent(content) {
      tinymce.activeEditor.setContent(content)
    },
  },
}
</script>
<style lang="scss"></style>
