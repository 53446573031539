import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapActions } from 'vuex';
import * as types from '@board/store/types'
import * as accountTypes from '@account/store/types'
import { IEDate, uGetDateFormat } from '@appbase/util/date-helper';
import { open, OpenType } from '@board/open-handler'; 

import { LoadingBar } from '@appbase/components/loading'

@Component({
    components: {
        LoadingBar
    }, 

    computed: mapGetters({
        isLoading: types.BOARD_LOADDING,
        articleList: types.FETCH_ARTICLES,
        popupBaseUrl: types.POPUP_BASEURL,
        pageSet: types.FETCH_PAGESET,
        boardConfig: types.BOARD_CONFIG,
        user: accountTypes.USER
    }),

    methods: mapActions({
        fetchArticle: types.FETCH_ARTICLES,
        fetchPageSet: types.FETCH_PAGESET
    })
})
export default class BoardSummary  extends Vue {
    @Prop({ type: Number }) currentPage!: number;
    @Prop({ type: Boolean }) adminMode!: boolean;

    fetchArticle!: (searchset: article.SearchSet) => Promise<article.PagedData<article.Article>>;
    fetchPageSet!: (pageSet: boardModule.state.PageSet) => void;
    
    articleList!: Array<article.Article>;
    pageSet!: boardModule.state.PageSet;
    popupBaseUrl!: string;
    boardConfig!: board.BoardItem;
    isLoading!: boolean;
    user!: account.User;

    itemsPerPage: number = 4;
    searchItems: number = 0;
    hasReadPermission: boolean = true;

    @Watch('boardConfig')
    onBoardChanged(newVal: board.BoardItem) {
        this.loadPermission();
        this.fetchPageSet(<boardModule.state.PageSet>{
            boardId: newVal.boardId,
            currentPage: 1,
            searchField: 0,
            searchText: null,
            preface1: null,
            preface2: null
        });
    }

    @Watch('pageSet')
    onPageSetChange(newVal: boardModule.state.PageSet, oldVal: boardModule.state.PageSet) {    
        var searchSet = <article.SearchSet>{
            userCode: this.user.id,
            boardId: newVal.boardId,
            page: newVal.currentPage,
            itemsPerPage: this.itemsPerPage,
            searchField: newVal.searchField,
            searchFieldValue: newVal.searchText,
            prefaceId1: newVal.preface1,
            prefaceId2: newVal.preface2,
            sortField: 0,
            sortOrder: false,
            includeContent: true,
            useNotice: this.boardConfig.usePrimaryNotice
        }
        
        this.fetchArticle(searchSet).then(res => {           
            this.searchItems = res.totalCount;
            this.$emit('articlesChanged', this.itemsPerPage, res.totalCount, newVal.currentPage, res.data.length);
        })
    }

    created() {
        if (this.boardConfig != null) {
            this.onBoardChanged(this.boardConfig);
        }
    }

    loadPermission() {
        /*TODO 권한설정*/
        // if(this.menus != null && this.board != null) {
        //     var permission = this.menus.find(m => m.menuId == this.board.menuId);
        //     this.hasReadPermission = permission != null && permission.read;        
        // }                
    }

    getCreatedDate(article: article.Article) {
        return uGetDateFormat('yyyy-mm-dd H:i:s', IEDate(article.createdDate));
    }

    getImage(article: article.Article) {
        if(article.hasImage) {
            return `/cm/api/BoardApi/Article/thumbnail?fileName=${encodeURIComponent(article.imageFilePath)}`;            
        } else {
            var divElem = document.createElement('div');
            divElem.innerHTML = article.content;

            var imageSource = divElem.querySelector('img')?.src;

            if(imageSource) {
                return imageSource;
            } else {
                return `/cm/api/BoardApi/Board/boardImage?fileName=${encodeURIComponent(this.boardConfig.boardImage)}`;
            }        
        }                
    }

    getInnerText(article: article.Article) {
        var divElem = document.createElement('div');
        divElem.innerHTML = article.content;

        return divElem.textContent || divElem.innerText || "";
    }

    checkArticleExpired(expiredDate: Date | null) {
        if(expiredDate == null) {
            return false;
        } else {
            var tDate = new Date();
            var today = uGetDateFormat('yyyy-MM-DD', tDate);
            var articlceDate = uGetDateFormat('yyyy-MM-DD', IEDate(expiredDate));
            return articlceDate < today
        }
    }

    async goDetailPage(article: article.Article) {
        if(this.checkArticleExpired(article.expiredDate)) {
            await (window as any).alertAsync(this.$t("M_BB_ExpiredPost").toString());
            return;
        }
        
        //게시글 읽기 권한
        if (this.hasReadPermission) {            
            open(this.isTeams || !this.boardConfig.usePopup ? 
                OpenType.ModalRead : OpenType.PopupRead,
                {
                    boardId: this.boardConfig.boardId,
                    articleId: article.articleId,
                    baseUrl: this.popupBaseUrl
                });
        } else {
            await (window as any).alertAsync(this.$t('M_BB_NoReadPermission').toString());
            return;
        }
    }
}