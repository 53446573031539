import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import { dispatchEventBus } from "@appbase/util/eventBus"
import BoardReadBase from "@board/components/board/BoardRead"
import * as permissionHelper from "@appbase/util/permission-helper"
import menuHelpder from "@appbase/util/menu-helper"
import HelpdeskCategories from "./HelpdeskCategories.vue"
import HelpdeskComponent from "./HelpdeskComponent.vue"
import api from "@board/api"
import localStorageHelper from '@appbase/util/local-storage-helper'
import { open, OpenType } from '@board/open-handler'; 
@Component({
    components: {
        HelpdeskCategories,
        HelpdeskComponent,
    },
})
export default class HelpdeskRead extends BoardReadBase {
    categoryValue: helpdesk.CategoryValue | null = null
    isHelpdeskAdmin: boolean = false
    helpdeskCaseData!: helpdesk.ResponseHelpdeskCase

    txtEditBtn: string = ""

    isLoadedHelpdeskData: boolean = false
    serverityDisplayName: string = ""

    mounted() {
        //팝업 창 하단에 알수없는 공백이 생김 임시 조치로 해당 코드 작성
        //디자인팀 요청해서 수정 필요 함
        let el = window.document.querySelector("#common-wrapper") as HTMLElement
        //el.style.height = "initial"
    }

    created() {
        this.initPermission()
        //
        this.initHelpdeskData()

        //수정버튼 txt 초기화
        //등록상태인경우 체크해서 다시 변경됨
        this.txtEditBtn = this.$t("접수").toString()
    }

    initHelpdeskData() {
        api.helpdesk.getHelpdeskCase(this.currentArticle).then((result) => {
            //조회 결과 있는 경우
            if (result.detail != null) {
                this.helpdeskCaseData = result

                //set categoryValue
                this.categoryValue = {
                    lv1: this.helpdeskCaseData.detail.categoryLv1Id,
                    lv2: this.helpdeskCaseData.detail.categoryLv2Id,
                    service: this.helpdeskCaseData.detail.serviceTypeId,
                    formHtml: ''
                }

                if (typeof result.detail.status == "string") {
                    switch (result.detail.status) {
                        case "Registration":
                            result.detail.status = 0
                            break
                        case "Receive":
                            result.detail.status = 1
                            break
                        case "Assign":
                            result.detail.status = 2
                            break
                        case "Completed":
                            result.detail.status = 3
                            break
                        case "Reject":
                            result.detail.status = 4
                            break
                        case "Hold":
                            result.detail.status = 5
                            break
                    }
                }

                //serverity
                if (typeof result.detail.serverity == "string") {
                    this.serverityDisplayName = result.detail.serverity
                    switch (result.detail.serverity) {
                        case "None":
                            result.detail.serverity = 0
                            break
                        case "Low":
                            result.detail.serverity = 1
                            break
                        case "Moderate":
                            result.detail.serverity = 2
                            break
                        case "High":
                            result.detail.serverity = 3
                            break
                        case "Critical":
                            result.detail.serverity = 4
                            break
                    }
                }

                this.txtEditBtn =
                    this.isHelpdeskAdmin && result.detail.status == 0
                        ? this.$t("접수").toString()
                        : this.$t("수정").toString()
            }

            this.isLoadedHelpdeskData = true
        })
    }

    async initPermission() {
        let strMenuId = this.$route.query.MENUID

        let menuPermission = null

        if (strMenuId == "NaN") {
            let boardId = this.$route.query.SYSID as string
            let findMenu = menuHelpder.findOneBySysId(parseInt(boardId), "BB")

            if (findMenu) {
                menuPermission = permissionHelper.GetMenuPermission(
                    findMenu.menuId
                )
            } else {
                await (window as any).alertAsync("잘못된 접근입니다.")
                this.onCloseClick()
            }
        } else {
            //리스트에서 유입된 경우
            menuPermission = permissionHelper.GetMenuPermission(
                parseInt(strMenuId as string)
            )
        }

        //메뉴 관리자에 등록된 사용자( Helpdesk 관리자 )
        if (menuPermission.IsAdmin == true) {
            this.isHelpdeskAdmin = true
        }
    }

    getServerityCss(displayname: string) {
         
        switch (displayname.toLowerCase()) {
            case "low":
                return "badge-gray"
            case "moderate":
                return "badge-blue"
            case "high":
                return "badge-yellow"
            case "critical":
                return "badge-red"
            default: //기본값 카테고리와 동일 색상 , None 등 추후 생성될 수도 있는 
                return "badge-green"
        }
    }

    //OVERRIDE
    async handleDelete() {
        let deleteMessage: string = this.$t("M_BB_DeleteMessage") as string
        var returnMessage = await(window as any).confirmAsync(deleteMessage)

        if (returnMessage) {
            this.deleteArticle(<article.DeleteArticle>{
                articleId: this.currentArticle,
                deleteUser: {
                    userCode: this.user.personCode,
                    userName: this.user.displayName,
                    userName_En: this.user.displayDeptNameEn,
                    deptName: this.user.titleName,
                    deptName_En: this.user.titleNameEn,
                    userJobTitle: this.user.deptName,
                    userJobTitle_En: this.user.deptNameEn,
                },
            }).then((res) => {
                //실행전에 창이 닫혀서 finally block 안에 추가
                //헬프데스크 케이스 삭제 로직 추가
                //res=0 일 때가 성공 케이스 확인 됨
                //별도 조건 없이 로직 수행
                api.helpdesk
                    .deleteHelpdeskCase(this.currentArticle)
                    .then((result) => {
                        //정상작동 확인
                    })
                    .finally(() => {
                        var eventTarget = this.isModal ? window : window.opener
                        dispatchEventBus(eventTarget, "boardlist-reload", "")
                        this.onCloseClick()
                    })
            })
        }
    }

     //2022-07-14 댓글 등록 후 팀즈 알림 발송 
    //대상 작성자, 등록된 카테고리 담당자
    //댓글 api 완료 콜백
    //이후에 teams 알림 발송함
    onCreatedComment(comment : string){
        //alert("댓글 작성 완료")
        
        api.helpdesk.sendCommentNotification(this.currentArticle, comment) 
    }

    //답글 
    async onReplyClick() {
        if(this.readConfig == null)
            return;

        var getReplyHeadRawHtml = function(article: article.ArticleDetail | null) {
            if(article == null)
                return "";

            var returnHtml = `
            <p>&nbsp;</p>
            <hr style="width: 98%; display: inline-block;" tabindex="-1" />
            <div id="divRplyFwdMsg" dir="ltr">    
                <span style="font-size: 10pt; color: #000000; font-family: dotum;"
                    data-mce-style="font-size: 10pt; color: #000000; font-family: dotum;">`;
                    returnHtml += `
                    <strong>작성자:</strong> ${article.createdUserName} / ${article.createdUserJobTitle} / ${article.createdDeptName}<br>
                    <strong>작성일:</strong> ${article.createdDate} <br>`
                    returnHtml += `
                    <strong>제목:</strong> ${article.title}</span>
                <div>&nbsp;</div>
            </div>`;
            
            return returnHtml;
        }

        var dispContent = `${getReplyHeadRawHtml(this.mArticle)}${this.mArticle?.content}`;

        localStorageHelper.set(`$board/${this.currentArticle}/title`, this.mArticle?.title);
        localStorageHelper.set(`$board/${this.currentArticle}/preface`, this.mArticle?.prefaceId);
        localStorageHelper.set(`$board/${this.currentArticle}/content`, dispContent);         
        

        await (window as any).alertAsync(this.popupBaseUrl)

        open(!this.isTeams && this.readConfig.usePopup ? 
            OpenType.PopupReply : OpenType.ModalReply, 
            {
                boardId: this.readConfig.boardId,
                parentArticle: this.currentArticle,
                baseUrl: this.popupBaseUrl
            });
    }
}
