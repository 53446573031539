import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({

})
export default class IFrameContainer  extends Vue {
    @Prop({ type: Number, default: 1024 }) width!: number;
    @Prop({ type: Number, default: 768 }) height!: number;
    @Prop({ type: String }) src!: string;
}