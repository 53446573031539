import Vue from 'vue';
import { Component , Prop , PropSync} from 'vue-property-decorator';
import Datepicker from 'vuejs-datepicker'
import * as dateutil from '@appbase/util/date-helper';
import { ko } from "vuejs-datepicker/dist/locale";
import { getLangProperty} from '@appbase/util';
import { IEDate } from '@appbase/util/date-helper';

@Component({
    components:{
        Datepicker
    }
})

export default class TimeSpanField extends Vue {
    @Prop ({ type: Object }) context!: board.ContentField;
    @PropSync('returnValue', { type: String }) timespanModel!: string;
    @Prop({ type: Object }) initOptions!: any;

    mStartDate: Date | null = null;
    mEndDate: Date | null = null;
    mStartDateBefore: Date | null = null;
    mEndDateBefore: Date | null = null;

    startPickerValid: boolean = true;
    endPickerValid: boolean = true;
    colspan: number = 1;

    localeKr: Object = ko;

    get displayName() {
        return getLangProperty(this.context, 'displayName');
    }

    created(){
        //수정
        if(this.timespanModel != null){
            var time = this.timespanModel.split('~');
            this.mStartDate = IEDate(time[0]);
            this.mEndDate = IEDate(time[1]);
        } else {
            this.mStartDate = new Date();
            var endDate = new Date();
            endDate.setDate(this.mStartDate.getDate() + 30);

            this.mEndDate = endDate;
            this.updateModel();
        }

        if (this.initOptions && this.initOptions.TimeSpanField) {
            var options = this.initOptions.TimeSpanField;
        }        
    }

    dateFormatter(date: Date) {
        return dateutil.uGetDateFormat('yyyy년 MM월 DD일', date);
    }

    updated(){
        this.updateModel();
    }

    checkPickerValidate(start: Date | null, end: Date | null){
        if(start == null || end == null){
            return true
        } 
        else {
            if(start > end){
                return false
            }
            else if(start < end){
                return true
            }
        }
    }

    async startSelected(date: Date){
        this.mStartDateBefore = this.mStartDate == null? null : new Date(this.mStartDate);

        if(this.checkPickerValidate(date, this.mEndDate)) {
            this.startPickerValid= true;
            date.setHours(0, 0, 0, 0);
        } else {
            this.startPickerValid = false;
            await (window as any).alertAsync('마감일이 시작일보다 빠를 수 없습니다.');
        }
    }

    async endSelected(date: Date){
        this.mEndDateBefore = this.mEndDate == null? null : new Date(this.mEndDate);

        if(this.checkPickerValidate(this.mStartDate, date)){
            this.endPickerValid = true;
            date.setHours(23, 59, 59, 59);
        } else{
            this.endPickerValid = false;
            await (window as any).alertAsync('마감일일 시작일보다 빠를 수 없습니다.');
        }

    }

    updateModel() {
        if(!this.startPickerValid) {
            this.mStartDate = this.mStartDateBefore == null ? null : new Date(this.mStartDateBefore);
            this.startPickerValid = true;
        }
        if(!this.endPickerValid){
            this.mEndDate = this.mEndDateBefore == null ? null : new Date(this.mEndDateBefore);
            this.endPickerValid = true;
        }

        if(this.startPickerValid && this.endPickerValid) {
            var dispStart = this.mStartDate == null ? "" : dateutil.uGetDateFormat('yyyy-MM-DD', this.mStartDate);
            var dispEnd = this.mEndDate == null ? "" : dateutil.uGetDateFormat('yyyy-MM-DD', this.mEndDate);

            this.timespanModel = `${dispStart} ~ ${dispEnd}`;
        }
    }
}